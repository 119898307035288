import { MockEnv } from '../../globals/constants'
import { KeyStore } from '../storage/keystore/keystore'
import { BannedSequenceGroup } from '../story/bannedsequences'
import { EndOfSamplingSequence } from '../story/eossequences'
import { LogitBiasGroup } from '../story/logitbias'
import { StorySettings, TextGenerationSettings } from '../story/storysettings'
import { User, UserInformation, UserPriority, UserSubscription } from '../user/user'
import { UserSettings } from '../user/settings'
import {
    MockGenerationRequest,
    MockLoginRequest,
    MockRegisterRequest,
    MockSubscriptionBindRequest,
    MockSubscriptionRequest,
    MockSubscriptionChangeRequest,
    MockRecoveryInitiationRequest,
    MockMockRecoverySubmitRequest,
    MockPurchaseStepsRequest,
    MockVerifyEmailRequest,
    MockDeleteAccountRequest,
    MockImageGenerationRequest,
    MockImageAnnotateRequest,
    MockImageUpscaleRequest,
    MockChangeMailingListConsentRequest,
    MockImageToolRequest,
} from './mockrequest'
import {
    RemoteGenerationRequest,
    RemoteLoginRequest,
    RemoteRegisterRequest,
    RemoteSubscriptionRequest,
    RemoteSubscriptionBindRequest,
    RemoteSubscriptionChangeRequest,
    RemoteRecoveryInitiationRequest,
    RemoteRecoverySubmitRequest,
    RemotePurchaseStepsRequest,
    RemoteChangeAuthRequest,
    RemoteVerifyEmailRequest,
    LogProbs,
    RemoteDeleteAccountRequest,
    RemoteImageGenerationRequest,
    RemoteImageAnnotateRequest,
    RemoteImageUpscaleRequest,
    RemoteChangeMailingListConsentRequest,
    RemoteImageToolRequest,
} from './remoterequest'
import { TextGenerationModel } from './model'
import { ImageTool } from '../image/imagetoolutil'
import { Language } from '../../hooks/useLocalization'

export interface IGenerationRequestResponse {
    text?: string
    error?: string
    status?: string
    tokens?: number[]
    logprobs?: LogProbs[]
}

export interface IGenerationRequest {
    context: number[]
    parameters: TextGenerationSettings
    user: User
    model: TextGenerationModel

    request(): Promise<IGenerationRequestResponse>

    requestStream(
        onToken: (
            token: string,
            index: number,
            final: boolean,
            tokenArr: number[],
            logprobs?: LogProbs[]
        ) => Promise<boolean>,
        onError: (err: { status: number; message: string }) => void,
        cancel?: {
            current: () => void
        }
    ): Promise<void>

    wrappedRequest(
        streamed: boolean,
        onToken: (
            token: string,
            index: number,
            final: boolean,
            tokenArr: number[],
            logprobs: LogProbs[]
        ) => Promise<boolean>,
        onError: (err: { status: number; message: string }) => void,
        cancel?: {
            current: () => void
        }
    ): Promise<void>
}

export interface ILoginResponse {
    subscription: UserSubscription
    session: {
        keystore: KeyStore
        authenticated: boolean
        auth_token: string
    }
    settings: UserSettings
    priority: UserPriority
    information: UserInformation
}
export interface ILoginRequest {
    access_key: string
    encryption_key: string
    auth_token?: string
    gift_key?: string

    login(): Promise<ILoginResponse>
}

export interface IRegisterResponse {
    auth_token: string
    canUseTrial?: boolean
}
export interface IRegisterRequest {
    access_key: string
    captcha: string
    email: string

    register(): Promise<IRegisterResponse>
}

export interface ISubscriptionResponse {
    subscription: UserSubscription
    priority: UserPriority
}
export interface ISubscriptionRequest {
    auth_token: string

    request(): Promise<ISubscriptionResponse>
}

export enum GiftKeyType {
    Subscription = 'sub',
    Steps = 'steps',
    SubAndSteps = 'subandsteps',
}

export interface ISubscriptionBindResponse {
    paymentProcessor: string
    giftKeyType?: GiftKeyType
    steps?: number
    requiresConfirmation?: boolean
    tier?: number
    duration?: number
}

export interface ISubscriptionBindRequest {
    auth_token: string
    paymentProcessor: string
    subscriptionId: string

    request(): Promise<ISubscriptionBindResponse>
}

export interface ISubscriptionChangeRequest {
    auth_token: string
    newSubscriptionPlan: string

    request(): Promise<void>
}

export interface IRecoveryInitiationRequest {
    email: string

    request(): Promise<void>
}

export interface IRecoverySubmitRequest {
    recoveryToken: string
    newAccessKey: string

    request(): Promise<void>
}

export interface IPurchaseStepsRequest {
    request(): Promise<void>
}

export interface IVerifyEmailRequest {
    request(): Promise<void>
}

export interface IDeleteAccountRequest {
    request(): Promise<void>
}

export interface IChangeMailingListConsentRequest {
    auth_token: string
    marketingConsent: boolean
    email?: string

    request(): Promise<void>
}

export interface AdditionalRequestData {
    phraseBias?: LogitBiasGroup[]
    bannedTokens?: BannedSequenceGroup[]
    eosSequences?: EndOfSamplingSequence[]
}

export const enum ImageGenerationModels {
    stableDiffusion = 'stable-diffusion',
    naiDiffusion = 'nai-diffusion',
    safeDiffusion = 'safe-diffusion',
    waifuDiffusion = 'waifu-diffusion',
    naiDiffusionFurry = 'nai-diffusion-furry',
    naiDiffusionFurry2 = 'nai-diffusion-furry2',
    dalleMini = 'dalle-mini',
    test = 'curated-diffusion-test',
    naiDiffusionInpainting = 'nai-diffusion-inpainting',
    safeDiffusionInpainting = 'safe-diffusion-inpainting',
    furryDiffusionInpainting = 'furry-diffusion-inpainting',
    custom = 'custom',
    naiDiffusionV2 = 'nai-diffusion-2',
    naiDiffusionXL = 'nai-diffusion-xl',
    naiDiffusionV3 = 'nai-diffusion-3',
    naiDiffusionV3Inpainting = 'nai-diffusion-3-inpainting',
    naiDiffusionFurryV3 = 'nai-diffusion-furry-3',
    naiDiffusionFurryV3Inpainting = 'nai-diffusion-furry-3-inpainting',
    unknown = 'unknown',
}

export enum ImageModelGroups {
    stableDiffusion = 'stableDiffusion',
    stableDiffusionGroup2 = 'stableDiffusionGroup2',
    stableDiffusionXL = 'stableDiffusionXL',
    stableDiffusionXLFurry = 'stableDiffusionXLFurry',
}

export const DEFAULT_IMAGE_MODEL = ImageGenerationModels.naiDiffusionV3

export function modelGroup(model: ImageGenerationModels): ImageModelGroups {
    switch (model) {
        case ImageGenerationModels.stableDiffusion:
        case ImageGenerationModels.naiDiffusion:
        case ImageGenerationModels.safeDiffusion:
        case ImageGenerationModels.waifuDiffusion:
        case ImageGenerationModels.naiDiffusionFurry:
        case ImageGenerationModels.test:
        case ImageGenerationModels.naiDiffusionInpainting:
        case ImageGenerationModels.safeDiffusionInpainting:
        case ImageGenerationModels.furryDiffusionInpainting: {
            return ImageModelGroups.stableDiffusion
        }
        case ImageGenerationModels.naiDiffusionV2: {
            return ImageModelGroups.stableDiffusionGroup2
        }
        case ImageGenerationModels.naiDiffusionXL:
        case ImageGenerationModels.naiDiffusionV3:
        case ImageGenerationModels.naiDiffusionV3Inpainting:
        case ImageGenerationModels.custom: {
            return ImageModelGroups.stableDiffusionXL
        }
        case ImageGenerationModels.naiDiffusionFurryV3:
        case ImageGenerationModels.naiDiffusionFurryV3Inpainting: {
            return ImageModelGroups.stableDiffusionXLFurry
        }
    }
    return ImageModelGroups.stableDiffusion
}

type ModelSupportInfo = {
    controlnet: boolean
    vibetransfer: boolean
    scaleMax: number
    negativePromptGuidance: boolean
    noiseSchedule: boolean
    inpainting: boolean
    // support for CFG delay is not a model concern, it's a "how recent is your deployment of hydra-node" concern.
    // only NAIv3-era models tend to run recent hydra code.
    cfgDelay: boolean
}

export function getModelSupportInfo(model: ImageGenerationModels): ModelSupportInfo {
    switch (model) {
        case ImageGenerationModels.stableDiffusion:
        case ImageGenerationModels.naiDiffusion:
        case ImageGenerationModels.safeDiffusion:
        case ImageGenerationModels.waifuDiffusion:
        case ImageGenerationModels.naiDiffusionFurry:
        case ImageGenerationModels.test:
        case ImageGenerationModels.naiDiffusionInpainting:
        case ImageGenerationModels.safeDiffusionInpainting:
        case ImageGenerationModels.furryDiffusionInpainting: {
            return {
                controlnet: true,
                vibetransfer: false,
                scaleMax: 25,
                negativePromptGuidance: false,
                noiseSchedule: false,
                inpainting: true,
                cfgDelay: false,
            }
        }
        case ImageGenerationModels.naiDiffusionV2: {
            return {
                controlnet: true,
                vibetransfer: false,
                scaleMax: 25,
                negativePromptGuidance: true,
                noiseSchedule: false,
                inpainting: true,
                cfgDelay: false,
            }
        }
        case ImageGenerationModels.naiDiffusionXL:
        case ImageGenerationModels.naiDiffusionV3:
        case ImageGenerationModels.naiDiffusionV3Inpainting:
        case ImageGenerationModels.naiDiffusionFurryV3:
        case ImageGenerationModels.naiDiffusionFurryV3Inpainting:
        case ImageGenerationModels.custom: {
            return {
                controlnet: false,
                vibetransfer: true,
                scaleMax: 10,
                negativePromptGuidance: true,
                noiseSchedule: true,
                inpainting: true,
                cfgDelay: true,
            }
        }
    }
    return {
        controlnet: false,
        vibetransfer: false,
        scaleMax: 25,
        negativePromptGuidance: false,
        noiseSchedule: false,
        inpainting: false,
        cfgDelay: false,
    }
}

export const enum StableDiffusionSampler {
    // Normal Res
    plms = 'plms',
    ddim = 'ddim',
    kEuler = 'k_euler',
    kEulerAncestral = 'k_euler_ancestral',
    kDpm2 = 'k_dpm_2',
    kDpm2Ancestral = 'k_dpm_2_ancestral',
    kLms = 'k_lms',
    kDpmpp2sAncestral = 'k_dpmpp_2s_ancestral',
    kDpmppSde = 'k_dpmpp_sde',
    kDpmpp2m = 'k_dpmpp_2m',
    kDpmAdaptive = 'k_dpm_adaptive',
    kDpmFast = 'k_dpm_fast',
    kDpmpp2mSde = 'k_dpmpp_2m_sde',
    kDpmpp3mSde = 'k_dpmpp_3m_sde',
    ddimv3 = 'ddim_v3',

    // High Res
    naiSmea = 'nai_smea', // Deprecated in favor of sm parameter
    naiSmeaDyn = 'nai_smea_dyn', // Deprecated in favor of sm_dyn parameter
}

export enum StableDiffusionSamplerNoise {
    native = 'native',
    karras = 'karras',
    exponential = 'exponential',
    polyexponential = 'polyexponential',
}

export function getAllowedNoiseForSampler(sampler: StableDiffusionSampler): StableDiffusionSamplerNoise[] {
    switch (sampler) {
        case StableDiffusionSampler.kEulerAncestral: {
            return [
                StableDiffusionSamplerNoise.native,
                StableDiffusionSamplerNoise.karras,
                StableDiffusionSamplerNoise.exponential,
                StableDiffusionSamplerNoise.polyexponential,
            ]
        }
        case StableDiffusionSampler.kDpmpp2sAncestral: {
            return [
                StableDiffusionSamplerNoise.native,
                StableDiffusionSamplerNoise.karras,
                StableDiffusionSamplerNoise.exponential,
                StableDiffusionSamplerNoise.polyexponential,
            ]
        }
        case StableDiffusionSampler.kDpmpp2m:
        case StableDiffusionSampler.kDpmpp2mSde: {
            return [
                StableDiffusionSamplerNoise.native,
                StableDiffusionSamplerNoise.karras,
                StableDiffusionSamplerNoise.exponential,
                StableDiffusionSamplerNoise.polyexponential,
            ]
        }
        case StableDiffusionSampler.kDpm2: {
            return [StableDiffusionSamplerNoise.exponential, StableDiffusionSamplerNoise.polyexponential]
        }
        case StableDiffusionSampler.kDpmppSde: {
            return [
                StableDiffusionSamplerNoise.native,
                StableDiffusionSamplerNoise.karras,
                StableDiffusionSamplerNoise.exponential,
                StableDiffusionSamplerNoise.polyexponential,
            ]
        }
        case StableDiffusionSampler.kEuler: {
            return [
                StableDiffusionSamplerNoise.native,
                StableDiffusionSamplerNoise.karras,
                StableDiffusionSamplerNoise.exponential,
                StableDiffusionSamplerNoise.polyexponential,
            ]
        }
        default: {
            // Not supported
            return []
        }
    }
}

export function getDefaultNoiseForSampler(sampler: StableDiffusionSampler): StableDiffusionSamplerNoise {
    switch (sampler) {
        // Euler is a first-order sampler, so should get good estimates on all schedules.
        // we've tuned Karras to spend steps where they're most needed. probably.
        case StableDiffusionSampler.kEuler:
        case StableDiffusionSampler.kEulerAncestral: {
            return StableDiffusionSamplerNoise.karras
        }
        // second-order single-step samplers: prefer Karras, so that we can spend enough of our small step budget
        // on body. these samplers perform two model invocations per step, so behind-the-scenes we halve the
        // step count. notionally, this better estimate means they can navigate even weird schedules like Native, *but*
        // native allocates way too few steps in the high-noise region, resulting in uncertainty in body shape.
        case StableDiffusionSampler.kDpmppSde:
        case StableDiffusionSampler.kDpmpp2sAncestral: {
            return StableDiffusionSamplerNoise.karras
        }
        // this is another second-order single-step sampler. exponential schedule is almost identical to Karras, so
        // I'll leave this how I found it rather than migrating it to Karras like the above.
        case StableDiffusionSampler.kDpm2: {
            return StableDiffusionSamplerNoise.exponential
        }
        // Multistep methods (DPM++ 2M, DPM++ 2M SDE) get best estimates on exponential or exponential-like schedules.
        // The current exponential and Karras schedules are nearly identical, so it doesn't make a big difference.
        // We no longer have an option for "purely exponential, the whole way", opting instead for a "3 schedules
        // in a trenchcoat" approach. So there's no way for the user to *really* optimize for a "perfect multistep
        // estimate". We fallback to first-order steps whenever the step size ratios get wonky anyway. Overall
        // there's not likely to be much difference between exp and Karras. We'll keep 2M on exp and put 2M SDE on
        // Karras, on the basis that that configuration received the most testing.
        case StableDiffusionSampler.kDpmpp2m: {
            return StableDiffusionSamplerNoise.exponential
        }
        case StableDiffusionSampler.kDpmpp2mSde: {
            return StableDiffusionSamplerNoise.karras
        }
        default: {
            // Not supported
            return StableDiffusionSamplerNoise.native
        }
    }
}

export function getChannelsForModel(model: ImageGenerationModels) {
    switch (model) {
        case ImageGenerationModels.dalleMini: {
            return 3
        }
        default: {
            // most models use a 4-channel VAE.
            // check this again for "model after NAIv3"
            return 4
        }
    }
}

export function getLatentResampleFactorForModel(model: ImageGenerationModels) {
    switch (model) {
        case ImageGenerationModels.dalleMini: {
            return 1
        }
        default: {
            // most models use an f8 VAE.
            return 8
        }
    }
}

export function pixelToLatentResolution(pixelResolution: Resolution, resampleFactor: number): Resolution {
    const [widthPx, heightPx] = pixelResolution
    const latentResolution: Resolution = [
        Math.floor(widthPx / resampleFactor),
        Math.floor(heightPx / resampleFactor),
    ]
    return latentResolution
}

export type Resolution = [width: number, height: number]
export type Dimensions = [channels: number, ...Resolution]

export function getSignalQuantity([channels, width, height]: Dimensions): number {
    return channels * width * height
}

/**
 * Arbitrary image size used as a reference for SNR calculations.
 * This is an NAIv3 standard portrait image.
 * At this resolution, we know that:
 *  sigma | feature size
 * ======================
 *  317   | mean colour of image
 *  20~14 | body
 *    4.5 | large hands
 *    1   | small hands
 *   <0.8 | fingernails
 * We can use this reference to determine "what sigma_max do I need to draw a coherent body",
 * as resolution or channel count increases (more redundancy in signal means a higher
 * amount of noise is required to achieve the same signal-to-noise ratio).
 * For example, if our canvas width and height both become 2x larger (increasing area by 4x)
 * then small hands are formed at sqrt(4)x the sigma from the reference image.
 * So doubling canvas edge length doubles the sigma at which these features are decided.
 */
export const snrReferenceChannelsAndDims: Dimensions = [4, Math.floor(832 / 8), Math.floor(1216 / 8)]
const snrReferenceQuantity: number = getSignalQuantity(snrReferenceChannelsAndDims)

export function getSNRMultiplierForResolution(channels: number, latentResolution: Resolution): number {
    const snrQuantity: number = getSignalQuantity([channels, ...latentResolution])
    return (snrQuantity / snrReferenceQuantity) ** 0.5
}

export const nonSmSamplers = new Set([
    StableDiffusionSampler.ddim,
    StableDiffusionSampler.plms,
    StableDiffusionSampler.kLms,
    StableDiffusionSampler.naiSmea,
    StableDiffusionSampler.naiSmeaDyn,
    StableDiffusionSampler.ddimv3,
])

export const nonNoiseScheduleSamplers = new Set([
    StableDiffusionSampler.ddim,
    StableDiffusionSampler.plms,
    StableDiffusionSampler.kLms,
    StableDiffusionSampler.naiSmea,
    StableDiffusionSampler.naiSmeaDyn,
    StableDiffusionSampler.ddimv3,
    StableDiffusionSampler.kDpmFast,
])

export const enum ControlnetModel {
    canny = 'canny',
    hed = 'hed',
    depth = 'depth',
    mlsd = 'mlsd',
    normal = 'normal',
    scribble = 'scribble',
    seg = 'seg',
}

export interface StableDiffusionParameters {
    width: number
    height: number
    scale: number
    seed?: string
    steps?: number
    n_samples: number
    advanced?: boolean
    strength?: number
    noise?: number
    sampler: StableDiffusionSampler
    image?: string
    sm?: boolean
    sm_dyn?: boolean
    aiUrl?: string
    uncond_scale?: number
}

export interface DalleMiniParameters {
    temperature: number
    top_k: number
    supercondition_factor: number
    n_samples: number
}

export type ImageGenerationAction = 'generate' | 'img2img' | 'infill'

export interface IImageGenerationRequest {
    user: User
    input: string
    model: ImageGenerationModels
    action: ImageGenerationAction
    parameters: StableDiffusionParameters | DalleMiniParameters
    request(): Promise<Buffer[]>
}

export interface IImageUpscaleRequest {
    user: User
    image: string
    height: number
    width: number
    scale: number
    request(): Promise<Buffer[]>
}

export enum ImageAnnotationModels {
    canny = 'canny',
    hed = 'hed',
    midas = 'midas',
    mlsd = 'mlsd',
    uniformer = 'uniformer',
    fakeScribble = 'fake_scribble',
}

export interface IImageAnnotateRequest {
    user: User
    model: ImageAnnotationModels
    parameters: any
    request(): Promise<Buffer[]>
}

export interface IImageToolRequest {
    user: User
    tool: ImageTool
    parameters: any
    request(): Promise<Buffer[]>
}

export function getGenerationRequest(
    user: User,
    textContext: string,
    context: number[],
    storySettings: StorySettings,
    additional?: AdditionalRequestData,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    paramOverride?: any,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    captureProps?: any,
    capture?: (event: string, data?: any) => void,
    skipFirstTokens?: number
): IGenerationRequest {
    return MockEnv
        ? new MockGenerationRequest(user, context, storySettings)
        : new RemoteGenerationRequest(
              user,
              textContext,
              context,
              storySettings,
              additional,
              paramOverride,
              captureProps,
              capture,
              skipFirstTokens
          )
}

export function getLoginRequest(
    accesskey: string,
    encryptionkey: string,
    auth_token?: string
): ILoginRequest {
    return MockEnv
        ? new MockLoginRequest(accesskey, encryptionkey, auth_token)
        : new RemoteLoginRequest(accesskey, encryptionkey, auth_token)
}

export function getRegisterRequest(
    accesskey: string,
    encryptionkey: string,
    captcha: string,
    email: string,
    giftkey?: string,
    locale?: string,
    allowMarketingEmails?: boolean
): IRegisterRequest {
    return MockEnv
        ? new MockRegisterRequest(
              accesskey,
              encryptionkey,
              captcha,
              email,
              giftkey,
              locale,
              allowMarketingEmails
          )
        : new RemoteRegisterRequest(
              accesskey,
              encryptionkey,
              captcha,
              email,
              giftkey,
              locale,
              allowMarketingEmails
          )
}

export function getSubscriptionRequest(auth_token: string): ISubscriptionRequest {
    return MockEnv ? new MockSubscriptionRequest(auth_token) : new RemoteSubscriptionRequest(auth_token)
}

export function getSubscriptionBindRequest(
    auth_token: string,
    paymentProcessor: string,
    subscriptionId: string,
    confirmedIgnore?: boolean,
    confirmedReplace?: boolean
): ISubscriptionBindRequest {
    return MockEnv
        ? new MockSubscriptionBindRequest(auth_token, paymentProcessor, subscriptionId)
        : new RemoteSubscriptionBindRequest(
              auth_token,
              paymentProcessor,
              subscriptionId,
              confirmedIgnore,
              confirmedReplace
          )
}

export function getSubscriptionChangeRequest(
    auth_token: string,
    newSubscriptionPlan: string
): ISubscriptionChangeRequest {
    return MockEnv
        ? new MockSubscriptionChangeRequest(auth_token, newSubscriptionPlan)
        : new RemoteSubscriptionChangeRequest(auth_token, newSubscriptionPlan)
}

export function getRecoveryInitiationRequest(email: string, locale?: string): IRecoveryInitiationRequest {
    return MockEnv
        ? new MockRecoveryInitiationRequest(email, locale)
        : new RemoteRecoveryInitiationRequest(email, locale)
}

export function getResetSubmitRequest(recoveryToken: string, newAccessKey: string): IRecoverySubmitRequest {
    return MockEnv
        ? new MockMockRecoverySubmitRequest(recoveryToken, newAccessKey)
        : new RemoteRecoverySubmitRequest(recoveryToken, newAccessKey)
}

export function getPurchaseStepsRequest(auth_token: string, steps: number): IPurchaseStepsRequest {
    return MockEnv ? new MockPurchaseStepsRequest() : new RemotePurchaseStepsRequest(auth_token, steps)
}

export function getChangeAuthRequest(
    auth_token: string,
    old_key: string,
    new_key: string,
    email?: string
): RemoteChangeAuthRequest {
    // TODO: no mock env
    return new RemoteChangeAuthRequest(auth_token, old_key, new_key, email)
}

export function getVerifyEmailRequest(verificationToken: string, uiLanguage: Language): IVerifyEmailRequest {
    return MockEnv
        ? new MockVerifyEmailRequest()
        : new RemoteVerifyEmailRequest(verificationToken, uiLanguage)
}

export function getDeleteAccountRequest(deletionToken: string): IDeleteAccountRequest {
    return MockEnv ? new MockDeleteAccountRequest() : new RemoteDeleteAccountRequest(deletionToken)
}

export function getImageGenerationRequest(
    user: User,
    input: string,
    model: ImageGenerationModels,
    action: ImageGenerationAction,
    parameters: StableDiffusionParameters | DalleMiniParameters
): IImageGenerationRequest {
    return MockEnv
        ? new MockImageGenerationRequest(user, input, model, action, parameters)
        : new RemoteImageGenerationRequest(user, input, model, action, parameters)
}

export function getImageAnnotateRequest(
    user: User,
    model: ImageAnnotationModels,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    parameters: any
): IImageAnnotateRequest {
    return MockEnv
        ? new MockImageAnnotateRequest(user, model, parameters)
        : new RemoteImageAnnotateRequest(user, model, parameters)
}

export function getImageUpscaleRequest(
    user: User,
    images: string,
    height: number,
    width: number,
    scale: number
): IImageUpscaleRequest {
    return MockEnv
        ? new MockImageUpscaleRequest(user, images, width, height, scale)
        : new RemoteImageUpscaleRequest(user, images, width, height, scale)
}

export function getChangeMailingListConsentRequest(
    auth_token: string,
    marketingConsent: boolean,
    uiLanguage: Language,
    email?: string
): IChangeMailingListConsentRequest {
    return MockEnv
        ? new MockChangeMailingListConsentRequest(auth_token, marketingConsent, uiLanguage, email)
        : new RemoteChangeMailingListConsentRequest(auth_token, marketingConsent, uiLanguage, email)
}

export function getImageToolRequest(user: User, tool: ImageTool, parameters: any): IImageToolRequest {
    return MockEnv
        ? new MockImageToolRequest(user, tool, parameters)
        : new RemoteImageToolRequest(user, tool, parameters)
}
