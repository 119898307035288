import { Buffer } from 'buffer'
import { SSE } from 'sse.js'
import { toast } from 'react-toastify'
import JSZip from 'jszip'
import { BackendUrl, Environment } from '../../globals/constants'
import { logError, logWarning } from '../../util/browser'
import { fetchWithTimeout } from '../../util/general'
import { KeyStore } from '../storage/keystore/keystore'
import {
    LogitWarper,
    logitWarperNum,
    NoModule,
    StorySettings,
    TextGenerationSettings,
} from '../story/storysettings'
import { User, UserInformation, UserPriority, UserSubscription } from '../user/user'
import { getUserSetting, TTSType, UserSettings } from '../user/settings'
import {
    modelHasScaledRepetitionPenalty,
    modelSupportsEosToken,
    modelSupportsCfg,
    modelSupportsModules,
    modelSupportsPhraseBias,
    modelSupportsRepPenWhitelist,
    MODEL_EUTERPE_V2,
    MODEL_GENJIJP6B_V2,
    modelSupportsTopG,
    modelSupportsMirostat,
    modelSupportsMath1,
    modelSupportMinP,
} from '../ai/model'
import Encoder from '../../tokenizer/encoder'
import { getGlobalEncoder, prepareGlobalEncoder, WorkerInterface } from '../../tokenizer/interface'
import { checkNeed, cutNeedyTokens } from '../../tokenizer/util'
import {
    prepareBadWords,
    getEncoderBannedBrackets,
    getEncoderBannedAdventureBrackets,
    prepareBiasGroups,
    getEncoderDefaultBias,
    prepareStopSequences,
    getEncoderAdventureEndSequences,
    getEncoderAdventureEOSTokens,
    getEncoderRepPenWhitelistTokens,
    getEncoderBannedTokens,
    getEncoderJpBannedBrackets,
} from '../../util/generationrequest'
import { getAvailiableModels, modelsCompatible, prefixIsDefault } from '../../util/models'
import {
    isAdventureModeStory,
    formatErrorResponse,
    logprobToProb,
    randomShortID,
    containsJapaneseText,
} from '../../util/util'
import { DarkOld } from '../../styles/themes/darkold'
import { themeEquivalent } from '../../styles/themes/theme'
import { Dark } from '../../styles/themes/dark'
import { EncoderType, getModelEncoderType } from '../../tokenizer/enums'
import { DefaultModel, modelFromModelId, normalizeModel, TextGenerationModel } from './model'
import {
    type Resolution,
    IGenerationRequest,
    IGenerationRequestResponse,
    ILoginRequest,
    ILoginResponse,
    IRegisterRequest,
    IRegisterResponse,
    IRecoveryInitiationRequest,
    ISubscriptionBindRequest,
    ISubscriptionChangeRequest,
    ISubscriptionRequest,
    ISubscriptionResponse,
    IRecoverySubmitRequest,
    AdditionalRequestData,
    StableDiffusionParameters,
    DalleMiniParameters,
    ImageGenerationModels,
    ISubscriptionBindResponse,
    StableDiffusionSampler,
    StableDiffusionSamplerNoise,
    nonSmSamplers,
    ImageGenerationAction,
    ImageAnnotationModels,
    ImageModelGroups,
    modelGroup,
    getLatentResampleFactorForModel,
    nonNoiseScheduleSamplers,
    pixelToLatentResolution,
    getModelSupportInfo,
    getSNRMultiplierForResolution,
    getChannelsForModel,
} from './request'
import { EndOfSamplingSequence } from '../story/eossequences'
import { TokenData, TokenDataFormat } from '../story/logitbias'
import { subscriptionIsActive } from '../../util/subscription'
import { ImageTool } from '../image/imagetoolutil'
import { Language } from '../../hooks/useLocalization'

function getBufferForTokenizer(encoderType: EncoderType): typeof Uint32Array | typeof Uint16Array {
    switch (encoderType) {
        case EncoderType.Llama3: {
            return Uint32Array
        }
        default: {
            return Uint16Array
        }
    }
}

export async function encodeInput(
    input: number[],
    encoderType: EncoderType
): Promise<{ input: string; length: number }> {
    const length = input.length
    const buffer = new (getBufferForTokenizer(encoderType))(input)
    const encoded = new Uint8Array(buffer.buffer, buffer.byteOffset, buffer.byteLength)
    return { input: Buffer.from(encoded).toString('base64'), length }
}

export async function decodeOutput(
    output: string,
    encoderType: EncoderType
): Promise<{ text: string; tokens: Uint16Array | Uint32Array }> {
    const encoded = new Uint8Array(Buffer.from(output, 'base64'))
    const buffer = new (getBufferForTokenizer(encoderType))(encoded.buffer)
    return { text: await new WorkerInterface().decode([...buffer], encoderType), tokens: buffer }
}

export function decodeToNumber(output: string, encoderType: EncoderType): number[] {
    const encoded = new Uint8Array(Buffer.from(output, 'base64'))
    return [...new (getBufferForTokenizer(encoderType))(encoded.buffer).values()]
}

export interface LogProbToken {
    token: number
    str: string
    before: number
    pBefore: number
    after: number | null
    pAfter: number | null
    partial: boolean
    chosen?: boolean
}

export interface LogProbs {
    chosen: LogProbToken
    afters: LogProbToken[]
    befores: LogProbToken[]
    excludedFromText?: boolean
    displayText?: string
    croppedToken?: boolean
}

async function mapLogProbs(probs: any, encoderType: EncoderType): Promise<LogProbs> {
    const worker = new WorkerInterface()
    return {
        chosen: {
            token: probs.chosen[0][0][0],
            before: probs.chosen[0][1][0],
            after: probs.chosen[0][1][1],
            // eslint-disable-next-line unicorn/no-await-expression-member
            str: (await worker.decode([probs.chosen[0][0]], encoderType)).replace(/\n/g, '\\n'),
            partial: !checkNeed(probs.chosen[0], encoderType).complete,
            pBefore: logprobToProb(probs.chosen[0][1][0]),
            pAfter: probs.chosen[0][1][1] !== null ? logprobToProb(probs.chosen[0][1][1]) : null,
            chosen: true,
        },
        afters: await Promise.all(
            probs.after.map(async (prob: any[]) => ({
                token: prob[0][0],
                before: prob[1][0],
                after: prob[1][1],
                // eslint-disable-next-line unicorn/no-await-expression-member
                str: (await worker.decode([prob[0][0]], encoderType)).replace(/\n/g, '\\n'),
                partial: !checkNeed(prob[0], encoderType).complete,
                pBefore: logprobToProb(prob[1][0]),
                pAfter: prob[1][1] !== null ? logprobToProb(prob[1][1]) : null,
            }))
        ),
        befores: await Promise.all(
            probs.before.map(async (prob: any[]) => ({
                token: prob[0][0],
                before: prob[1][0],
                after: prob[1][1],
                // eslint-disable-next-line unicorn/no-await-expression-member
                str: (await worker.decode([prob[0][0]], encoderType)).replace(/\n/g, '\\n'),
                partial: !checkNeed(prob[0], encoderType).complete,
                pBefore: logprobToProb(prob[1][0]),
                pAfter: prob[1][1] !== null ? logprobToProb(prob[1][1]) : null,
            }))
        ),
    }
}

function mapLogProbsSync(probs: any, encoderType: EncoderType): LogProbs {
    const encoder = getGlobalEncoder(encoderType)
    const str = encoder.decode([probs.chosen[0][0]]).replace(/\n/g, '\\n')
    return {
        chosen: {
            token: probs.chosen[0][0][0],
            before: probs.chosen[0][1][0],
            after: probs.chosen[0][1][1],
            str: str,
            partial: !checkNeed(probs.chosen[0], encoderType).complete || str.startsWith('{'),
            pBefore: logprobToProb(probs.chosen[0][1][0]),
            pAfter: probs.chosen[0][1][1] !== null ? logprobToProb(probs.chosen[0][1][1]) : null,
            chosen: true,
        },
        afters:
            probs.after?.map((prob: any[]) => {
                const str = encoder.decode([prob[0][0]]).replace(/\n/g, '\\n')
                return {
                    token: prob[0][0],
                    before: prob[1][0],
                    after: prob[1][1],
                    str: str,
                    partial: !checkNeed(prob[0], encoderType).complete || str.startsWith('{'),
                    pBefore: logprobToProb(prob[1][0]),
                    pAfter: prob[1][1] !== null ? logprobToProb(prob[1][1]) : null,
                }
            }) ?? [],
        befores:
            probs.before?.map((prob: any[]) => {
                const str = encoder.decode([prob[0][0]]).replace(/\n/g, '\\n')
                return {
                    token: prob[0][0],
                    before: prob[1][0],
                    after: prob[1][1],
                    str: str,
                    partial: !checkNeed(prob[0], encoderType).complete || str.startsWith('{'),
                    pBefore: logprobToProb(prob[1][0]),
                    pAfter: prob[1][1] !== null ? logprobToProb(prob[1][1]) : null,
                }
            }) ?? [],
    }
}

const excludedValues: Set<keyof TextGenerationSettings> = new Set([
    'textGenerationSettingsVersion',
    'eos_token_id',
    'bad_words_ids',
    'logit_bias_groups',
    'order',
])

function textGenToParams(settings: TextGenerationSettings) {
    const params: any = {}
    for (const key of Object.keys(settings) as (keyof TextGenerationSettings)[]) {
        if (!excludedValues.has(key)) {
            params[key] = settings[key]
        }
    }
    return params
}

const captureParamsWhitelist = new Set([
    'temperature',
    'max_length',
    'min_length',
    'top_k',
    'top_p',
    'top_a',
    'typical_p',
    'tail_free_sampling',
    'repetition_penalty',
    'repetition_penalty_range',
    'repetition_penalty_slope',
    'repetition_penalty_frequency',
    'repetition_penalty_presence',
    'cfg_scale',
    'phrase_rep_pen',
    'top_g',
    'mirostat_tau',
    'mirostat_lr',
])

const OLD_TEXT_BACKEND_MODELS = new Set([
    TextGenerationModel.neo2b,
    TextGenerationModel.j6b,
    TextGenerationModel.j6bv3,
    TextGenerationModel.j6bv4,
    TextGenerationModel.genjijp6b,
    TextGenerationModel.genjijp6bv2,
    TextGenerationModel.genjipython6b,
    TextGenerationModel.euterpev0,
    TextGenerationModel.euterpev2,
    TextGenerationModel.krakev1,
    TextGenerationModel.krakev2,
    TextGenerationModel.cassandra,
    TextGenerationModel.commentBot,
    TextGenerationModel.infill,
    TextGenerationModel.clio,
])

export class RemoteGenerationRequest implements IGenerationRequest {
    textContext: string
    context: number[]
    parameters: TextGenerationSettings
    user: User
    storySettings: StorySettings
    additional?: AdditionalRequestData
    paramOverride?: any
    model: TextGenerationModel
    endExcludeSequences: number[][]
    stripSequences: number[][]
    captureProps: any
    capture: ((event: string, data?: any) => void) | undefined
    usedPreset: string | undefined
    id = randomShortID()
    skipTokenCount = 0
    constructor(
        user: User,
        textContext: string,
        context: number[],
        storySettings: StorySettings,
        additional?: AdditionalRequestData,
        paramOverride?: Record<string, unknown>,
        captureProps?: Record<string, unknown>,
        capture?: (event: string, data?: any) => void,
        skipFirstTokens?: number
    ) {
        this.textContext = textContext
        this.context = context
        this.storySettings = { ...storySettings }
        this.parameters = JSON.parse(JSON.stringify(storySettings.parameters))
        this.user = user
        this.paramOverride = paramOverride
        this.additional = additional
        this.model =
            this.paramOverride?.model ||
            this.storySettings.model ||
            this.user.settings.defaultModel ||
            DefaultModel
        if (this.paramOverride?.model) delete this.paramOverride.model
        this.endExcludeSequences = []
        this.stripSequences = []
        this.captureProps = captureProps
        this.capture = capture
        if (skipFirstTokens) {
            this.skipTokenCount = skipFirstTokens
        }
    }

    private captureGenerationEvent(request: RequestInit, addedProps: any): void {
        // Sends a posthog event for the generation request. Called for both streamed and non-streamed requests when
        // the generation is complete.
        const body = JSON.parse(request.body as string)
        if (this.capture) {
            // For everything in the whitelist, copy from the request, prefixing with params_
            const params: Record<string, any> = {}
            for (const key of Object.keys(body.parameters)) {
                if (captureParamsWhitelist.has(key)) {
                    params['params_' + key] = body.parameters[key]
                }
            }
            this.capture('text:generated', {
                ...this.captureProps,
                ...addedProps,
                banBrackets: this.storySettings.banBrackets,
                module: prefixIsDefault(body.parameters.prefix) ? body.parameters.prefix : 'custom',
                order: body.parameters.order,
                model: body.model,
                inputLength: this.context.length,
                logitBiasExpLength: body.parameters.logit_bias_exp?.length,
                badWordsLength: body.parameters.bad_words_ids?.length,
                stopSequencesLength: body.parameters.stop_sequences?.length,
                params_num_logprobs: body.parameters.num_logprobs,
                // If preset id starts with default-
                preset: this.storySettings.preset?.startsWith('default-')
                    ? this.storySettings.preset
                    : 'custom',
                ...params,
                isAdventure: isAdventureModeStory(this.storySettings),
                repetition_penalty_default_whitelist: this.parameters.repetition_penalty_default_whitelist,
            })
        }
    }

    async prepareRequest(): Promise<RequestInit> {
        const encoderType = getModelEncoderType(this.model)
        await prepareGlobalEncoder(encoderType)

        if (encoderType == EncoderType.CLIP) {
            throw new Error('remote request does not support clip encoder')
        }

        const request: RequestInit = {
            mode: 'cors',
            cache: 'no-store',
            headers: this.user.noAccount
                ? { 'Content-Type': 'application/json', 'x-correlation-id': this.id }
                : {
                      'Content-Type': 'application/json',
                      Authorization: 'Bearer ' + this.user.auth_token,
                      'x-correlation-id': this.id,
                  },
            method: 'POST',
        }
        // Copy params
        let params = textGenToParams(this.parameters)
        // downcast requested model to available model if required
        const modelOptions = getAvailiableModels(this.user.subscription.tier >= 3, void 0, true)
        this.model = (
            modelOptions.find((m) => m.str === normalizeModel(this.model)) ??
            modelOptions.find((m) => m.str === normalizeModel(this.storySettings.model)) ??
            modelOptions.find(
                (m) => m.str === normalizeModel(getUserSetting(this.user.settings, 'defaultModel'))
            ) ??
            modelOptions.find((m) => m.str === DefaultModel) ??
            modelOptions[0]
        ).str

        // Add banned tokens
        params.bad_words_ids = await prepareBadWords(this.additional?.bannedTokens ?? [], encoderType)
        params.bad_words_ids = [...getEncoderBannedTokens(encoderType), ...params.bad_words_ids]

        // Add additional banned tokens
        if (
            this.storySettings.banBrackets // Some bracket bans now handled server side for llama3 but some are still frontend
        ) {
            params.bad_words_ids = [
                ...params.bad_words_ids,
                ...getEncoderBannedBrackets(getModelEncoderType(this.model)),
            ]
            // If the context contains Japanese text, add Japanese bracket bans
            const text = await new WorkerInterface().decode(this.context, encoderType)
            if (containsJapaneseText(text)) {
                params.bad_words_ids = [
                    ...params.bad_words_ids,
                    ...getEncoderJpBannedBrackets(getModelEncoderType(this.model)),
                ]
            }
        }
        if (isAdventureModeStory(this.storySettings)) {
            params.bad_words_ids = [
                ...params.bad_words_ids,
                ...getEncoderBannedAdventureBrackets(getModelEncoderType(this.model)),
            ]
            // For models that support eos
            if (modelSupportsEosToken(this.model)) {
                params.eos_token_id = getEncoderAdventureEOSTokens(getModelEncoderType(this.model))
                params.min_length = Math.max(5, params.min_length ?? 0)
                this.endExcludeSequences = getEncoderAdventureEndSequences(getModelEncoderType(this.model))
                // On text adventure with a small prompt, we want to ban the EOS token to force
                // the model to generate a decently long prompt.
                if (this.context.length < 30) {
                    params.bad_words_ids = [...params.bad_words_ids, [params.eos_token_id]]
                }
            }
        }

        // Add phrase bias
        let logit_bias_exp = modelSupportsPhraseBias(this.model)
            ? await prepareBiasGroups(this.additional?.phraseBias ?? [], encoderType)
            : []
        logit_bias_exp = [...logit_bias_exp, ...getEncoderDefaultBias(getModelEncoderType(this.model))]
        // Set stop sequences
        const stop_sequences = await prepareStopSequences(this.additional?.eosSequences ?? [], encoderType)
        if (stop_sequences.length > 0) {
            params.stop_sequences = stop_sequences
        }

        // Add default biases
        const defaultBiasList = []
        let defaultBiasBias = -0.12
        if (getUserSetting(this.user.settings, 'defaultBias') && modelSupportsPhraseBias(this.model)) {
            switch (encoderType) {
                case EncoderType.GPT2: {
                    defaultBiasList.push([8162], [46256, 224])
                    break
                }
                case EncoderType.PileNAI: {
                    defaultBiasList.push([9264], [50260])
                    break
                }
                case EncoderType.Pile: {
                    defaultBiasList.push([9264])
                    break
                }
                case EncoderType.Nerdstash:
                case EncoderType.NerdstashV2: {
                    defaultBiasList.push([23], [21])
                    defaultBiasBias = -0.08
                    break
                }
            }
        }

        for (const token of defaultBiasList) {
            if (!logit_bias_exp.some((sequence) => JSON.stringify(sequence) === JSON.stringify(token))) {
                logit_bias_exp = [
                    ...logit_bias_exp,
                    {
                        sequence: token,
                        bias: defaultBiasBias,
                        ensure_sequence_finish: false,
                        generate_once: false,
                    },
                ]
            }
        }

        let translatedRepPenalty = this.parameters.repetition_penalty
        if (modelHasScaledRepetitionPenalty(this.model)) {
            const oldRange = 1 - 8.0
            const newRange = 1 - 1.525
            translatedRepPenalty = ((translatedRepPenalty - 1) * newRange) / oldRange + 1
        }

        if (!modelSupportsModules(this.model) || this.storySettings.prefix === '') {
            this.storySettings.prefix = NoModule
        }

        // Biases are backwards on Genji for unknown reasons
        if (modelsCompatible(this.model, TextGenerationModel.genjijp6bv2)) {
            const tempBiases = []
            for (const l of logit_bias_exp) {
                tempBiases.push({ ...l, bias: l.bias * -1 })
            }
            logit_bias_exp = tempBiases
        }
        // Manage Order
        let order = []
        for (const o of this.parameters.order ?? []) {
            if (!o.enabled) {
                switch (o.id) {
                    case LogitWarper.Temperature: {
                        delete params.temperature
                        break
                    }
                    case LogitWarper.TopK: {
                        delete params.top_k
                        break
                    }
                    case LogitWarper.TopP: {
                        delete params.top_p
                        break
                    }
                    case LogitWarper.TFS: {
                        delete params.tail_free_sampling
                        break
                    }
                    case LogitWarper.TopA: {
                        delete params.top_a
                        break
                    }
                    case LogitWarper.TypicalP: {
                        delete params.typical_p
                        break
                    }
                    case LogitWarper.Cfg: {
                        delete params.cfg_scale
                        delete params.cfg_uc
                        break
                    }
                    case LogitWarper.TopG: {
                        delete params.top_g
                        break
                    }
                    case LogitWarper.Mirostat: {
                        delete params.mirostat_tau
                        delete params.mirostat_lr
                        break
                    }
                    case LogitWarper.Math1: {
                        delete params.math1_temp
                        delete params.math1_quad
                        delete params.math1_quad_entropy_scale
                        break
                    }
                    case LogitWarper.MinP: {
                        delete params.min_p
                        break
                    }
                }
            } else {
                if (o.id === LogitWarper.TopG) {
                    // RIP TopG
                    delete params.top_g
                    continue
                }
                order.push(logitWarperNum(o.id))
            }
        }

        // Remove cfg from request if model does not support it
        if (!modelSupportsCfg(this.model)) {
            delete params.cfg_scale
            delete params.cfg_uc
            order = order.filter((o) => {
                return o !== logitWarperNum(LogitWarper.Cfg)
            })
        }

        // Remove TopG from request if model does not support it
        if (!modelSupportsTopG(this.model)) {
            delete params.top_g
            order = order.filter((o) => {
                return o !== logitWarperNum(LogitWarper.TopG)
            })
        }

        // Remove Mirostat from request if model does not support it
        if (!modelSupportsMirostat(this.model)) {
            delete params.mirostat_tau
            delete params.mirostat_lr
            order = order.filter((o) => {
                return o !== logitWarperNum(LogitWarper.Mirostat)
            })
        }

        // Remove Math1 from request if model does not support it
        if (!modelSupportsMath1(this.model)) {
            delete params.math1_temp
            delete params.math1_quad
            delete params.math1_quad_entropy_scale
            order = order.filter((o) => {
                return o !== logitWarperNum(LogitWarper.Math1)
            })
        }

        // Remove min p from request if model does not support it
        if (!modelSupportMinP(this.model)) {
            delete params.min_p
            order = order.filter((o) => {
                return o !== logitWarperNum(LogitWarper.MinP)
            })
        }

        params = { ...params }

        const encoder = getGlobalEncoder(encoderType) as Encoder
        // Give warning for banned/biased tokens that are out of range
        const maxTokens = encoder.totalTokens()
        const validBias = []
        for (const bias of logit_bias_exp ?? []) {
            if (bias.sequence.some((token) => token < 0 || token >= maxTokens)) {
                toast(
                    `Bias [${bias.sequence.join(
                        ', '
                    )}] contains tokens that are out of range and will be ignored.`
                )
            } else {
                validBias.push(bias)
            }
        }
        if (validBias.length > 0) {
            logit_bias_exp = validBias
        } else {
            delete params.logit_bias_exp
        }

        // If the default whitelist is enabled, set the whitelist to the default
        // whitelist for the model
        if (params.repetition_penalty_default_whitelist && modelSupportsRepPenWhitelist(this.model)) {
            params.repetition_penalty_whitelist = await getEncoderRepPenWhitelistTokens(
                encoderType,
                isAdventureModeStory(this.storySettings)
            )
        }
        delete params.repetition_penalty_default_whitelist

        // Resolve prefix overrides
        if (this.paramOverride?.prefixOverride !== undefined) {
            this.storySettings.prefix = this.paramOverride.prefixOverride
            delete this.paramOverride.prefixOverride
        }

        // Handle pedia prefix bans
        if (this.storySettings.prefix === 'special_pedia') {
            // Add additional banned tokens
            const tokens = [
                [24, 24], //--------
                [24, 85, 24], //----\n----
                [24, 1629], //------
                [24, 49287], //----:
                [24, 49255], //-----
            ]
            params.bad_words_ids = [...params.bad_words_ids, ...tokens]
        }

        const validBans = []
        for (const token of params.bad_words_ids ?? []) {
            if (token < 0 || token >= maxTokens) {
                toast(`Banned token [${token}] is out of range and will be ignored.`)
            } else {
                validBans.push(token)
            }
        }
        if (validBans.length > 0) {
            params.bad_words_ids = validBans
        } else {
            delete params.bad_words_ids
        }

        // Handle instruct stop sequence
        if (this.storySettings.prefix === 'special_instruct') {
            if (encoderType === EncoderType.Llama3) {
                // Llama 3 only do token ' {{' and set as eos token id instead
                const arr = await new WorkerInterface().encode(' {{', encoderType)
                const id = arr[0]
                params.eos_token_id = id
                // Force min length to be at least 2
                params.min_length = Math.max(params.min_length ?? 0, 2)
                this.endExcludeSequences = [
                    ...(this.endExcludeSequences ?? []),
                    ...(await prepareStopSequences(
                        [new EndOfSamplingSequence(new TokenData(' {{', TokenDataFormat.RawString))],
                        encoderType
                    )),
                ]

                // Remove stop tokens from ban list if they are there
                params.bad_words_ids = params.bad_words_ids?.filter((token: number[]) => {
                    if (token[0] === id && token.length === 1) return false
                    return true
                })
            } else {
                // Limit of 8 stop sequences, so remove until we have 6
                params.stop_sequences = params.stop_sequences?.slice(0, 6)
                // Stop sequences for " {{" and "{{"
                params.stop_sequences = [
                    ...(params.stop_sequences ?? []),
                    ...(await prepareStopSequences(
                        [
                            new EndOfSamplingSequence(new TokenData(' {{', TokenDataFormat.RawString)),
                            new EndOfSamplingSequence(new TokenData('{{', TokenDataFormat.RawString)),
                        ],
                        encoderType
                    )),
                ]
                // Add to end exclude sequences

                const worker = new WorkerInterface()
                const stop1 = await worker.encode(' {{', encoderType)
                const stop2 = await worker.encode('{{', encoderType)
                const exclude1 = await worker.encode(' {{}}', encoderType)
                const exclude2 = await worker.encode('{{}}', encoderType)
                this.endExcludeSequences = [...(this.endExcludeSequences ?? []), stop1, stop2]
                this.stripSequences = [...(this.stripSequences ?? []), exclude1, exclude2]

                // Remove stop tokens from ban list if they are there
                params.bad_words_ids = params.bad_words_ids?.filter((token: number[]) => {
                    if ((token[0] === stop1[0] || token[0] === stop2[0]) && token.length === 1) return false
                    return true
                })
            }
        }

        // prevent backend from going nuts
        if (params.top_k < 0) {
            delete params.top_k
        }
        if (params.top_p <= 0 || params.top_p > 1.0) {
            delete params.top_p
        }
        if (params.tail_free_sampling <= 0 || params.tail_free_sampling > 1.0) {
            delete params.tail_free_sampling
        }
        if (!params.bad_words_ids || params.bad_words_ids.length === 0) {
            delete params.bad_words_ids
        }

        const tokenized = await encodeInput(this.context, encoderType)
        if (params.repetition_penalty_range == 0) {
            delete params.repetition_penalty_range
        }
        if (params.repetition_penalty_slope == 0) {
            delete params.repetition_penalty_slope
        }

        request.body = JSON.stringify({
            input: tokenized.input,
            model: this.model,
            parameters: {
                ...params,
                min_length: Math.min(
                    Math.max(params.min_length, 1),
                    this.user.subscription.tier >= 3 ? 500 : 500
                ),
                max_length: Math.min(
                    Math.max(params.max_length, 1),
                    this.user.subscription.tier >= 3 ? 500 : 500
                ),
                repetition_penalty: translatedRepPenalty,
                generate_until_sentence: getUserSetting(
                    this.user.settings,
                    'continueGenerationToSentenceEnd'
                ),
                use_cache: false,
                use_string: false,
                return_full_text: false,
                prefix: this.storySettings.prefix,
                logit_bias_exp: logit_bias_exp.length > 0 ? logit_bias_exp : undefined,
                num_logprobs: getUserSetting(this.user.settings, 'enableLogprobs')
                    ? getUserSetting(this.user.settings, 'logprobsCount')
                    : undefined,
                order: order,
                bracket_ban: this.storySettings.banBrackets ?? true,
                ...this.paramOverride,
            },
        })
        return request
    }

    async request(cancel?: { current: () => boolean }): Promise<IGenerationRequestResponse> {
        const request = await this.prepareRequest()

        const controller = new AbortController()

        if (cancel) {
            cancel.current = () => {
                controller.abort('generation cancelled')
                return true
            }
        }

        const useOldTextBackend = OLD_TEXT_BACKEND_MODELS.has(this.model)
        const response = await fetchWithTimeout(
            useOldTextBackend ? BackendUrl.Generate : BackendUrl.TextGenerate,
            { ...request, signal: controller.signal },
            40000 + this.parameters.max_length * 50,
            void 0,
            void 0,
            this.id
        )
        const json = await response.json()
        const encoderType = getModelEncoderType(this.model)
        let outputTokens
        let output
        let tokenExcludeArr
        if (json.output) {
            try {
                outputTokens = decodeToNumber(json.output, encoderType)
                tokenExcludeArr = outputTokens.map((t) => {
                    return { token: t, excluded: false }
                })
                for (const eos of this.endExcludeSequences) {
                    const sliced = tokenExcludeArr.slice(-1 * eos.length)
                    if (sliced.length === eos.length && sliced.every((t, i) => t.token === eos[i])) {
                        // Mark the tokens as excluded
                        for (let i = 0; i < eos.length; i++) {
                            tokenExcludeArr[tokenExcludeArr.length - eos.length + i].excluded = true
                        }
                    }
                }
                // Slide out the strip sequences
                // Unlike end exclude sequences, strip sequences are removed no matter where they are
                for (const strip of this.stripSequences) {
                    for (let i = 0; i < tokenExcludeArr.length; i++) {
                        const sliced = tokenExcludeArr.slice(i, i + strip.length)
                        if (sliced.length === strip.length && sliced.every((t, i) => t.token === strip[i])) {
                            // Mark the tokens as excluded
                            for (let j = i; j < i + strip.length; j++) {
                                tokenExcludeArr[j].excluded = true
                            }
                        }
                    }
                }
                if (tokenExcludeArr.every((t) => t.excluded))
                    return { error: 'Stop Sequence exclusion resulted in empty output' }

                const worker = new WorkerInterface()
                const nonExcludedTokens = tokenExcludeArr.filter((t) => !t.excluded).map((t) => t.token)

                // Skip the first tokens if requested
                if (this.skipTokenCount > 0) {
                    nonExcludedTokens.splice(0, this.skipTokenCount)
                }

                output = await worker.decode(cutNeedyTokens(nonExcludedTokens, encoderType), encoderType)
            } catch (error) {
                logError(error, false)
                output = json.output
            }
        }
        if (json.error)
            return {
                error: `[${this.id}] ${json.error ?? json.message}`,
            }

        if (response.status !== 200 && response.status !== 201)
            return {
                error: `[${this.id}] ${json.error ?? json.message}`,
            }

        const logprobs: LogProbs[] | undefined = json.logprobs
            ? json.logprobs.map((l: any) => mapLogProbsSync(l, encoderType))
            : undefined

        // If cropped token is present, mark the first token as cropped
        if (logprobs && this.paramOverride?.cropped_token !== undefined && logprobs[0]) {
            logprobs[0].croppedToken = true
        }

        // Mark the last tokens as excluded from the text if it was excluded
        if (tokenExcludeArr && logprobs) {
            for (const [i, element] of tokenExcludeArr.entries()) {
                if (element.excluded) {
                    logprobs[i].excludedFromText = true
                }
            }
        }
        if (this.model === TextGenerationModel.infill && output.endsWith('<|infillend|>')) {
            output = output.slice(0, -'<|infillend|>'.length)
            if (logprobs) logprobs[logprobs.length - 1].excludedFromText = true
        }

        // If llama3 and the output ends in a newline, and
        // the final token of the tokenExcludeArr is the excluded token, remove the
        // newline from the output.
        if (
            encoderType === EncoderType.Llama3 &&
            isAdventureModeStory(this.storySettings) &&
            output.endsWith('\n') &&
            tokenExcludeArr &&
            tokenExcludeArr[tokenExcludeArr.length - 1].excluded
        ) {
            output = output.slice(0, -1)
            const worker = new WorkerInterface()
            if (logprobs) {
                const str = await worker.decode([logprobs[logprobs.length - 2].chosen.token], encoderType)
                logprobs[logprobs.length - 2].displayText = str.slice(0, -1)
            }
        } else if (encoderType === EncoderType.Llama3 && output.endsWith('\n')) {
            // Otherwise if it's llama3 and ends in a newline, remove the newline
            output = output.slice(0, -1)
            if (logprobs) {
                const worker = new WorkerInterface()
                const str = await worker.decode([logprobs[logprobs.length - 1].chosen.token], encoderType)
                logprobs[logprobs.length - 1].displayText = str.slice(0, -1)
            }
        }

        this.captureGenerationEvent(request, {
            streamed: false,
        })
        return {
            text: output,
            error: json.error ?? json.message,
            status: `${response.status}` || `${json.statusCode}` || `${json.status}`,
            tokens: tokenExcludeArr?.map((t) => t.token),
            logprobs: logprobs,
        }
    }

    async requestStream(
        onToken: (
            token: string,
            index: number,
            final: boolean,
            tokenArr: number[],
            logprobs: LogProbs[]
        ) => Promise<boolean>,
        onError: (err: { status: number; message: string }) => void,
        cancel?: {
            current: () => boolean
        }
    ): Promise<void> {
        const request = await this.prepareRequest()
        const tokenBacklog: { token: number; ptr: number; final: boolean; logprobs?: LogProbs }[] = []
        let index = 0

        const useOldTextBackend = OLD_TEXT_BACKEND_MODELS.has(this.model)

        const source = new SSE(
            useOldTextBackend ? BackendUrl.GenerateStream : BackendUrl.TextGenerateStream,
            {
                headers: request.headers,
                payload: request.body,
            }
        )
        const encoderType = getModelEncoderType(this.model)
        const encoder = getGlobalEncoder(encoderType)
        const timoutFunction = () => {
            source.close()
            onError({
                status: 408,
                message: `[${this.id}] Error: Timeout - Unable to reach NovelAI servers. Please wait for a moment and try again`,
            })
        }
        let timeout = setTimeout(timoutFunction, 30000)

        // Set cancel to a function that will send a faked final onToken and close the source
        if (cancel) {
            cancel.current = () => {
                onToken('', -1, true, [], [])
                source.close()
                return true
            }
        }

        source.addEventListener('newToken', async (e: any) => {
            let cancel = false
            const data = JSON.parse(e.data)
            if (data.error) {
                clearTimeout(timeout)
                source.close()
                onError({ status: 0, message: data.error })
                return
            }
            clearTimeout(timeout)
            timeout = setTimeout(timoutFunction, 5000)
            const token = data.token ? decodeToNumber(data.token, encoderType)[0] : -1
            tokenBacklog[data.ptr] = {
                token,
                ptr: data.ptr,
                final: data.final,
                logprobs: data.logprobs ? mapLogProbsSync(data.logprobs, encoderType) : undefined,
            }
            // If ptr cropped token this is the first token mark the token as cropped
            if (
                this.paramOverride?.cropped_token !== undefined &&
                data.ptr === 0 &&
                data.logprobs &&
                tokenBacklog[0].logprobs
            ) {
                tokenBacklog[0].logprobs.croppedToken = true
            }
            const tokens: {
                token: number
                ptr: number
                final: boolean
                logprobs?: LogProbs
                blank?: boolean
            }[] = []
            for (let i = index; i < data.ptr + 1; i++) {
                const tokenData = tokenBacklog[i]
                if (tokenData === undefined) {
                    break
                }
                if (tokenData.token === -1) {
                    continue
                }
                tokens.push(tokenData)
            }

            // If skipTokenCount is set, skip the first tokens by marking them as blank
            if (this.skipTokenCount > 0) {
                for (let i = index; i < this.skipTokenCount; i++) {
                    const token = tokens[i]
                    token.blank = true
                    if (token.logprobs) {
                        token.logprobs.excludedFromText = true
                    }
                }
            }

            // If the last token isn't the final one, ends with a newline, we're on
            // the llama3 tokenizer, and on adventure mode, return early and handle the
            // token next time.
            if (tokens.length > 0 && tokens[tokens.length - 1].final) {
                const str = encoder.decode([tokens[tokens.length - 1].token])
                if (
                    !tokens[tokens.length - 1].final &&
                    str.endsWith('\n') &&
                    encoderType === EncoderType.Llama3 &&
                    isAdventureModeStory(this.storySettings)
                ) {
                    // Send up dummy token
                    cancel = !(await onToken('', tokens[tokens.length - 1].ptr, false, [], [])) || cancel
                    return
                }
            }

            // If the current tokens match an eos token pattern, hold them until the next token
            let heldTokens: {
                token: number
                ptr: number
                final: boolean
                logprobs?: LogProbs
                blank?: boolean
            }[] = []

            const combinedSequences = [...this.endExcludeSequences, ...this.stripSequences]
            if (combinedSequences.length > 0) {
                const matches: number[] = Array.from({ length: combinedSequences.length }).fill(0) as number[]
                for (const [, token] of tokens.entries()) {
                    for (const [j, eos] of combinedSequences.entries()) {
                        if (eos[matches[j]] === token.token) {
                            matches[j]++
                        } else {
                            matches[j] = 0
                        }
                    }
                }
                const highest = [...matches.entries()].sort((a, b) => b[1] - a[1])[0]
                if (highest[1] > 0) {
                    heldTokens = tokens.splice(-1 * combinedSequences[highest[0]].length)
                }
            }
            for (const eos of this.endExcludeSequences) {
                const sliced = tokens.slice(-1 * eos.length)
                if (sliced.length === eos.length && sliced.every((t, i) => t.token === eos[i])) {
                    heldTokens = tokens.slice(-1 * eos.length)
                    break
                }
            }
            // If the current held tokens contain a strip sequence, mark those tokens as excluded from text
            for (const strip of this.stripSequences) {
                for (let i = 0; i < heldTokens.length; i++) {
                    const sliced = heldTokens.slice(i, i + strip.length)
                    if (sliced.length === strip.length && sliced.every((t, i) => t.token === strip[i])) {
                        // Mark the tokens as excluded
                        for (let j = i; j < i + strip.length; j++) {
                            heldTokens[j].blank = true
                            const temp = heldTokens[j]
                            if (temp.logprobs) {
                                temp.logprobs.excludedFromText = true
                            }
                        }
                    }
                }
            }

            // Check if more tokens required for actual character
            const need = checkNeed(
                tokens.map((t) => t.token),
                encoderType
            )
            if (need.complete || need.error) {
                // More tokens not needed or unitrim error occured and tokens should be released anyway

                const tempIndex = index
                index += tokens.length
                for (let i = tempIndex; i < tempIndex + tokens.length - 1; i++) {
                    cancel = !(await onToken('', i, false, [], [])) || cancel
                }
                const logprobs: LogProbs[] = []
                for (const token of tokens.map((t) => t.logprobs)) {
                    if (token) logprobs.push(token)
                }

                if (heldTokens.length > 0) {
                    for (const token of heldTokens.map((t) => t.logprobs)) {
                        if (token) {
                            logprobs.push(token)
                            if (data.final) token.excludedFromText = true
                        }
                    }
                }
                // Mark infillend as excluded from text
                if (this.model === TextGenerationModel.infill) {
                    logprobs.forEach((logprob) => {
                        if (logprob.chosen.token === 50258) {
                            logprob.excludedFromText = true
                        }
                    })
                }

                let tokenString = encoder.decode([
                    ...tokens
                        // filter out <|infillend|> when using inline model
                        .filter((t) => !(this.model === TextGenerationModel.infill && t.token === 50258))
                        .filter((t) => !t.blank)
                        .map((t) => t.token),
                ])

                // If on llama3 and adventure mode, and the token string ends with \n,
                // and held tokens contains the end exclude sequence, remove the newline
                if (
                    encoderType === EncoderType.Llama3 &&
                    isAdventureModeStory(this.storySettings) &&
                    tokenString.endsWith('\n') &&
                    heldTokens.length > 0 &&
                    heldTokens.every((t) => t.token === this.endExcludeSequences[0][0]) &&
                    data.final
                ) {
                    tokenString = tokenString.slice(0, -1)
                    if (logprobs?.length > 0) {
                        logprobs[logprobs.length - 2].displayText = encoder
                            .decode([logprobs[logprobs.length - 2].chosen.token])
                            .slice(0, -1)
                    }
                } else {
                    // If we're on llama3, it's the final token, and it ends with a newline, remove the newline
                    if (encoderType === EncoderType.Llama3 && data.final && tokenString.endsWith('\n')) {
                        tokenString = tokenString.slice(0, -1)
                        if (logprobs?.length > 0) {
                            logprobs[logprobs.length - 1].displayText = encoder
                                .decode([logprobs[logprobs.length - 1].chosen.token])
                                .slice(0, -1)
                        }
                    }
                }

                cancel =
                    !(await onToken(
                        tokenString,
                        data.ptr,
                        data.final,
                        [
                            ...tokens.map((t) => t.token),
                            ...(data.final ? heldTokens.map((t) => t.token) : []),
                        ],
                        logprobs
                    )) || cancel
            } else if (data.final || cancel) {
                // More tokens required but that was the last one
                for (let i = index; i < index + tokens.length - 1; i++) {
                    await onToken('', i, false, [], [])
                }
                const logprobs: LogProbs[] = []
                for (const token of heldTokens.map((t) => t.logprobs)) {
                    if (token) {
                        logprobs.push(token)
                    }
                }

                await onToken('', data.ptr, true, heldTokens ? heldTokens.map((t) => t.token) : [], logprobs)
                index = data.ptr + 1
            }
            if (data.final) {
                clearTimeout(timeout)
                this.captureGenerationEvent(request, {
                    streamed: true,
                })
            } else if (cancel) {
                clearTimeout(timeout)
                source.close()
                await onToken('', data.ptr + 1, true, [], [])
            }
        })
        source.addEventListener('error', (err: any) => {
            clearTimeout(timeout)
            source.close()
            if (err?.detail?.type !== 'abort') {
                onError({
                    status: err?.detail?.statusCode ?? 'unknown status',
                    message: `[${this.id}] ${
                        (err?.detail?.message || err?.data) ??
                        (index === 0 ? "Couldn't connect to the AI." : 'Unknown error, please try again.')
                    }`,
                })
                logWarning(err, true, 'streaming error')
            }
        })

        source.stream()
    }

    // wrappedRequest exists to give a common interface to request and requestStream
    async wrappedRequest(
        streamed: boolean,
        onToken: (
            token: string,
            index: number,
            final: boolean,
            tokenArr: number[],
            logprobs: LogProbs[]
        ) => Promise<boolean>,
        onError: (err: { status: number; message: string }) => void,
        cancel: {
            current: () => boolean
        }
    ): Promise<void> {
        if (streamed) {
            await this.requestStream(onToken, onError, cancel)
        } else {
            const result = await this.request(cancel)
            if (result.error) {
                onError({ status: 0, message: result.error })
            } else {
                const text = result.text ?? ''
                const tokens = result.tokens ?? []
                const logprobs: LogProbs[] = result.logprobs ?? []
                onToken(text, 0, true, tokens, logprobs)
            }
        }
    }
}

export class RemoteLoginRequest implements ILoginRequest {
    access_key: string
    encryption_key: string
    auth_token?: string
    id = randomShortID()
    constructor(access_key: string, encryption_key: string, auth_token?: string) {
        this.access_key = access_key
        this.encryption_key = encryption_key
        this.auth_token = auth_token
    }

    async login(): Promise<ILoginResponse> {
        try {
            const result: ILoginResponse = {
                subscription: new UserSubscription(),
                session: {
                    keystore: new KeyStore(),
                    authenticated: false,
                    auth_token: '',
                },
                settings: new UserSettings(),
                priority: new UserPriority(),
                information: new UserInformation(),
            }

            const request: RequestInit = {
                mode: 'cors',
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                    'x-correlation-id': this.id,
                    'x-initiated-at': new Date().toISOString(),
                },
                method: 'POST',
                body: JSON.stringify({
                    key: this.access_key,
                }),
            }

            if (!this.auth_token) {
                const login = await fetchWithTimeout(
                    BackendUrl.Login,
                    request,
                    void 0,
                    void 0,
                    void 0,
                    this.id
                )
                if (!login.ok) {
                    logError(login, false)
                    throw await formatErrorResponse(login, void 0, this.id)
                }
                const response = await login.json()
                this.auth_token = response.accessToken
            }

            if (!this.auth_token) {
                throw new Error('missing auth token')
            }

            result.session.auth_token = this.auth_token
            this.id = randomShortID()
            request.headers = {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.auth_token,
                'x-correlation-id': this.id,
                'x-initiated-at': new Date().toISOString(),
            }
            request.method = 'GET'
            delete request.body

            const userdata = fetchWithTimeout(BackendUrl.UserData, request, void 0, void 0, void 0, this.id)

            const response = await userdata
            if (!response.ok) {
                throw await formatErrorResponse(response, void 0, this.id)
            }
            const json = await response.json()
            if (json?.statusCode && json?.statusCode !== 200) {
                throw json.message?.message || json.message || json.statusCode
            }

            const { subscription, keystore, settings, priority, information } = json

            try {
                await (keystore?.keystore
                    ? result.session.keystore.load(
                          keystore.keystore,
                          this.encryption_key,
                          keystore.changeIndex
                      )
                    : result.session.keystore.create(this.encryption_key))
            } catch (error) {
                logError(error)
                throw new Error('Unlocking the keystore failed, invalid Encryption Key?')
            }

            if (settings) {
                try {
                    result.settings = {
                        ...result.settings,
                        ...(typeof settings === 'string' ? JSON.parse(settings) : settings),
                    }
                } catch (error: any) {
                    logError(error)
                }
            }
            if (subscription) result.subscription = subscription
            if (priority) result.priority = priority
            if (information) result.information = information

            if (!result.settings.forceModelUpdate) {
                // eslint-disable-next-line unicorn/no-lonely-if
                if (result.settings.model !== MODEL_GENJIJP6B_V2) {
                    result.settings.forceModelUpdate = 1
                    result.settings.model = MODEL_EUTERPE_V2
                }
            }

            // Switch to new tts toggle
            if (!result.settings.settingsVersion) {
                if (result.settings.useTTS === true) {
                    result.settings.ttsType = 1
                    result.settings.useTTS = undefined
                }
                result.settings.settingsVersion = 0
            }
            if (result.settings.settingsVersion === 0) {
                if (result.settings.model) {
                    result.settings.defaultModel = modelFromModelId(result.settings.model)
                }
                result.settings.settingsVersion = 1
            }

            if (result.settings.settingsVersion === 1) {
                if (
                    result.settings.siteTheme &&
                    result.settings.siteTheme.name === 'NovelAI Dark' &&
                    themeEquivalent(result.settings.siteTheme, DarkOld)
                ) {
                    result.settings.siteTheme.colors = JSON.parse(JSON.stringify(Dark.colors))
                }
                result.settings.settingsVersion = 2
            }

            if (result.settings.settingsVersion === 2) {
                if (result.settings.ttsType === TTSType.Off) {
                    result.settings.speakOutputs = false
                    result.settings.speakComments = false
                    result.settings.speakInputs = false
                    result.settings.ttsType = TTSType.Streamed
                }
                result.settings.settingsVersion = 3
            }

            if (result.settings.settingsVersion === 3) {
                if (result.settings.siteTheme?.fonts) {
                    if (result.settings.siteTheme.fonts.selectedHeadings === 'Consolas') {
                        result.settings.siteTheme.fonts.selectedHeadings = 'Inconsolata'
                    }
                    if (result.settings.siteTheme.fonts.selectedHeadings === 'Comic Sans MS') {
                        result.settings.siteTheme.fonts.selectedHeadings = 'Comic Neue'
                    }
                    if (result.settings.siteTheme.fonts.selectedDefault === 'Consolas') {
                        result.settings.siteTheme.fonts.selectedDefault = 'Inconsolata'
                    }
                    if (result.settings.siteTheme.fonts.selectedDefault === 'Comic Sans MS') {
                        result.settings.siteTheme.fonts.selectedDefault = 'Comic Neue'
                    }
                    if (result.settings.siteTheme.fonts.default) {
                        result.settings.siteTheme.fonts.default =
                            result.settings.siteTheme.fonts.default.replace('"Comic Sans MS"', '"Comic Neue"')
                        result.settings.siteTheme.fonts.default =
                            result.settings.siteTheme.fonts.default.replace('"Consolas"', '"Inconsolata"')
                    }
                    if (result.settings.siteTheme.fonts.field) {
                        result.settings.siteTheme.fonts.field = result.settings.siteTheme.fonts.field.replace(
                            '"Comic Sans MS"',
                            '"Comic Neue"'
                        )
                        result.settings.siteTheme.fonts.field = result.settings.siteTheme.fonts.field.replace(
                            '"Consolas"',
                            '"Inconsolata"'
                        )
                    }
                }
                result.settings.settingsVersion = 4
            }

            // Unset default model if it's not Clio or already undefined
            if (result.settings.settingsVersion === 4) {
                if (
                    result.settings.defaultModel &&
                    result.settings.defaultModel !== TextGenerationModel.clio
                ) {
                    result.settings.defaultModel = undefined
                    // Also need to unset default preset and module
                    result.settings.defaultPreset = undefined
                    result.settings.defaultModule = undefined
                }
                result.settings.settingsVersion = 5
            }

            // Unset lore generation model if it's not Clio or already undefined
            if (result.settings.settingsVersion === 5) {
                if (
                    result.settings.loreGenModel &&
                    result.settings.loreGenModel !== TextGenerationModel.clio
                ) {
                    result.settings.loreGenModel = undefined
                    result.settings.legacyLoreGen = undefined
                }
                result.settings.settingsVersion = 6
            }

            // Default to use editor v2
            if (result.settings.settingsVersion === 6) {
                result.settings.useEditorV2 = undefined
                result.settings.settingsVersion = 7
            }

            // Unset Default model if it's not already Kayra or undefined
            if (result.settings.settingsVersion === 7) {
                if (
                    result.settings.defaultModel &&
                    result.settings.defaultModel !== TextGenerationModel.kayra
                ) {
                    result.settings.defaultModel = undefined
                    // Also need to unset default preset and module
                    result.settings.defaultPreset = undefined
                    result.settings.defaultModule = undefined
                }
                result.settings.settingsVersion = 8
            }

            // Unset trimTrailingSpaces and alwaysOverwriteConflicts
            if (result.settings.settingsVersion === 8) {
                result.settings.trimTrailingSpaces = undefined
                result.settings.alwaysOverwriteConflicts = undefined
                result.settings.settingsVersion = 9
            }

            result.session.authenticated = true

            return result
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }
}

export class RemoteRegisterRequest implements IRegisterRequest {
    access_key: string
    captcha: string
    encryption_key: string
    email: string
    gift_key?: string
    locale?: string
    allowMarketingEmails?: boolean
    id = randomShortID()

    constructor(
        access_key: string,
        encryption_key: string,
        captcha: string,
        email: string,
        gift_key?: string,
        locale?: string,
        allowMarketingEmails?: boolean
    ) {
        this.access_key = access_key
        this.captcha = captcha
        this.encryption_key = encryption_key
        this.email = email
        this.gift_key = gift_key
        this.locale = locale
        this.allowMarketingEmails = allowMarketingEmails
    }

    async register(): Promise<IRegisterResponse> {
        try {
            const request: RequestInit = {
                mode: 'cors',
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                    'x-correlation-id': this.id,
                    'x-initiated-at': new Date().toISOString(),
                },
                method: 'POST',
                body: JSON.stringify({
                    recaptcha: this.captcha,
                    recaptchaUseLatest: true,
                    key: this.access_key,
                    emailCleartext: this.email,
                    giftkey: this.gift_key ? this.gift_key : undefined,
                    locale: this?.locale ?? undefined,
                    allowMarketingEmails: this.allowMarketingEmails,
                }),
            }

            const register = await fetchWithTimeout(
                BackendUrl.Register,
                request,
                void 0,
                void 0,
                void 0,
                this.id
            )
            if (!register.ok) {
                logError(register, false)
                throw await formatErrorResponse(register, void 0, this.id)
            }
            const response = await register.json()
            const auth_token = response.accessToken
            const canUseTrial = response.canUseTrial

            return { auth_token, canUseTrial }
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }
}

export class RemoteSubscriptionRequest implements ISubscriptionRequest {
    auth_token: string
    id = randomShortID()
    constructor(auth_token: string) {
        this.auth_token = auth_token
    }
    async getSubscription(): Promise<UserSubscription> {
        try {
            const request: RequestInit = {
                mode: 'cors',
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.auth_token,
                    'x-correlation-id': this.id,
                    'x-initiated-at': new Date().toISOString(),
                },
                method: 'GET',
            }
            const response = await fetchWithTimeout(
                BackendUrl.Subscriptions,
                request,
                void 0,
                void 0,
                true,
                this.id
            )
            if (!response.ok) {
                logError(response, false)
                throw await formatErrorResponse(response, true, this.id)
            }
            return await response.json()
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }
    async getPriority(): Promise<UserPriority> {
        try {
            const request: RequestInit = {
                mode: 'cors',
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.auth_token,
                    'x-correlation-id': this.id,
                    'x-initiated-at': new Date().toISOString(),
                },
                method: 'GET',
            }
            const response = await fetchWithTimeout(
                BackendUrl.Priority,
                request,
                void 0,
                void 0,
                void 0,
                this.id
            )
            if (!response.ok) {
                logError(response, false)
                throw await formatErrorResponse(response, void 0, this.id)
            }
            return await response.json()
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }
    async request(): Promise<ISubscriptionResponse> {
        return {
            subscription: await this.getSubscription(),
            priority: await this.getPriority(),
        }
    }
}

export class RemoteSubscriptionBindRequest implements ISubscriptionBindRequest {
    auth_token: string
    paymentProcessor: string
    subscriptionId: string
    confirmedIgnore: boolean
    confirmedReplace: boolean
    id = randomShortID()

    constructor(
        auth_token: string,
        paymentProcessor: string,
        subscriptionId: string,
        confirmedIgnore?: boolean,
        confirmedReplace?: boolean
    ) {
        this.auth_token = auth_token
        this.paymentProcessor = paymentProcessor
        this.subscriptionId = subscriptionId
        this.confirmedIgnore = confirmedIgnore ?? false
        this.confirmedReplace = confirmedReplace ?? false
    }

    async request(): Promise<ISubscriptionBindResponse> {
        try {
            const request: RequestInit = {
                mode: 'cors',
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.auth_token,
                    'x-correlation-id': this.id,
                    'x-initiated-at': new Date().toISOString(),
                },
                method: 'POST',
                body: JSON.stringify({
                    paymentProcessor: this.paymentProcessor,
                    subscriptionId: this.subscriptionId,
                    confirmedIgnore: this.confirmedIgnore,
                    confirmedReplace: this.confirmedReplace,
                }),
            }

            const bind = await fetchWithTimeout(
                BackendUrl.SubscriptionBind,
                request,
                void 0,
                void 0,
                void 0,
                this.id
            )
            if (!bind.ok) {
                logError(bind, false)
                throw await formatErrorResponse(bind, void 0, this.id)
            }
            return await bind.json()
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }
}

export class RemoteSubscriptionChangeRequest implements ISubscriptionChangeRequest {
    auth_token: string
    newSubscriptionPlan: string
    id = randomShortID()

    constructor(auth_token: string, newSubscriptionPlan: string) {
        this.auth_token = auth_token
        this.newSubscriptionPlan = newSubscriptionPlan
    }

    async request(): Promise<void> {
        try {
            const request: RequestInit = {
                mode: 'cors',
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.auth_token,
                    'x-correlation-id': this.id,
                    'x-initiated-at': new Date().toISOString(),
                },
                method: 'POST',
                body: JSON.stringify({
                    newSubscriptionPlan: this.newSubscriptionPlan,
                }),
            }

            const bind = await fetchWithTimeout(
                BackendUrl.SubscriptionsChange,
                request,
                void 0,
                void 0,
                void 0,
                this.id
            )
            if (!bind.ok) {
                logError(bind, false)
                throw await formatErrorResponse(bind, void 0, this.id)
            }
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }
}

export class RemoteRecoveryInitiationRequest implements IRecoveryInitiationRequest {
    email: string
    locale?: string
    id = randomShortID()

    constructor(email: string, locale?: string) {
        this.email = email
        this.locale = locale ?? 'en'
    }

    async request(): Promise<void> {
        try {
            const request: RequestInit = {
                mode: 'cors',
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                    'x-correlation-id': this.id,
                    'x-initiated-at': new Date().toISOString(),
                },
                method: 'POST',
                body: JSON.stringify({
                    email: this.email,
                    locale: this.locale,
                }),
            }

            const bind = await fetchWithTimeout(
                BackendUrl.RecoveryInitiation,
                request,
                void 0,
                void 0,
                void 0,
                this.id
            )
            if (!bind.ok) {
                logError(bind, false)
                throw await formatErrorResponse(bind, false, this.id)
            }
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }
}

export class RemoteRecoverySubmitRequest implements IRecoverySubmitRequest {
    recoveryToken: string
    newAccessKey: string
    id = randomShortID()

    constructor(recoveryToken: string, newAccessKey: string) {
        this.recoveryToken = recoveryToken
        this.newAccessKey = newAccessKey
    }

    async request(): Promise<void> {
        try {
            const request: RequestInit = {
                mode: 'cors',
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                    'x-correlation-id': this.id,
                    'x-initiated-at': new Date().toISOString(),
                },
                method: 'POST',
                body: JSON.stringify({
                    recoveryToken: this.recoveryToken,
                    newAccessKey: this.newAccessKey,
                    deleteContent: true,
                }),
            }

            const bind = await fetchWithTimeout(
                BackendUrl.RecoverySubmit,
                request,
                void 0,
                void 0,
                void 0,
                this.id
            )
            if (!bind.ok) {
                logError(bind, false)
                throw await formatErrorResponse(bind, false, this.id)
            }
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }
}

export class RemotePurchaseStepsRequest {
    auth_token: string
    steps: number
    id = randomShortID()
    constructor(auth_token: string, steps: number) {
        this.auth_token = auth_token
        this.steps = steps
    }
    async request(): Promise<void> {
        try {
            const request: RequestInit = {
                mode: 'cors',
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.auth_token,
                    'x-correlation-id': this.id,
                    'x-initiated-at': new Date().toISOString(),
                },
                method: 'POST',
                body: JSON.stringify({
                    amount: this.steps,
                }),
            }

            const bind = await fetchWithTimeout(
                BackendUrl.PurchaseSteps,
                request,
                void 0,
                void 0,
                void 0,
                this.id
            )
            if (!bind.ok) {
                logError(bind, false)
                throw await formatErrorResponse(bind, false, this.id)
            }
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }
}

export class RemoteChangeAuthRequest {
    old_key: string
    new_key: string
    auth_token: string
    email?: string
    id = randomShortID()
    constructor(auth_token: string, old_key: string, new_key: string, email?: string) {
        this.old_key = old_key
        this.new_key = new_key
        this.auth_token = auth_token
        this.email = email
    }
    async request(): Promise<string> {
        try {
            const request: RequestInit = {
                mode: 'cors',
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.auth_token,
                    'x-correlation-id': this.id,
                    'x-initiated-at': new Date().toISOString(),
                },
                method: 'POST',
                body: JSON.stringify({
                    currentAccessKey: this.old_key,
                    newAccessKey: this.new_key,
                    newEmail: this.email,
                }),
            }
            const bind = await fetchWithTimeout(
                BackendUrl.ChangeAuth,
                request,
                void 0,
                void 0,
                void 0,
                this.id
            )
            if (!bind.ok) {
                logError(bind, false)
                const error =
                    bind.status === 409
                        ? new Error('The email address is already in use.')
                        : await formatErrorResponse(bind, false, this.id)
                throw error
            }
            const response = await bind.json()
            return response.accessToken
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }
}

export class RemoteVerifyEmailRequest {
    verificationToken: string
    uiLanguage: string
    id = randomShortID()
    constructor(verificationToken: string, uiLanguage: Language) {
        this.verificationToken = verificationToken
        this.uiLanguage = uiLanguage
    }
    async request(): Promise<void> {
        try {
            const request: RequestInit = {
                mode: 'cors',
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                    'x-correlation-id': this.id,
                    'x-initiated-at': new Date().toISOString(),
                },
                method: 'POST',
                body: JSON.stringify({
                    verificationToken: this.verificationToken,
                    uiLanguage: this.uiLanguage,
                }),
            }

            const bind = await fetchWithTimeout(
                BackendUrl.VerifyEmail,
                request,
                void 0,
                void 0,
                void 0,
                this.id
            )
            if (!bind.ok) {
                logError(bind, false)
                throw await formatErrorResponse(bind, false, this.id)
            }
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }
}

export class RemoteDeleteAccountRequest {
    deletionToken: string
    id = randomShortID()
    constructor(deletionToken: string) {
        this.deletionToken = deletionToken
    }
    async request(): Promise<void> {
        try {
            const request: RequestInit = {
                mode: 'cors',
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                    'x-correlation-id': this.id,
                    'x-initiated-at': new Date().toISOString(),
                },
                method: 'POST',
                body: JSON.stringify({
                    deletionToken: this.deletionToken,
                }),
            }

            const bind = await fetchWithTimeout(
                BackendUrl.VerifyDeleteAccount,
                request,
                void 0,
                void 0,
                void 0,
                this.id
            )
            if (!bind.ok) {
                logError(bind, false)
                throw await formatErrorResponse(bind, false, this.id)
            }
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }
}

export class RemoteImageGenerationRequest {
    user: User
    input: string
    model: ImageGenerationModels
    action: ImageGenerationAction
    parameters: StableDiffusionParameters | DalleMiniParameters
    constructor(
        user: User,
        input: string,
        model: ImageGenerationModels,
        action: ImageGenerationAction,
        parameters: StableDiffusionParameters | DalleMiniParameters
    ) {
        parameters = JSON.parse(JSON.stringify(parameters))
        this.user = user
        this.input = input
        this.model = model
        this.action = action
        if (!(parameters as StableDiffusionParameters).image) {
            delete (parameters as StableDiffusionParameters).strength
            delete (parameters as StableDiffusionParameters).noise
        }
        // If the sampler is set to nai_smea or nai_smea_dyn, unset and toggle sm/sm_dyn
        if ((parameters as StableDiffusionParameters).sampler === StableDiffusionSampler.naiSmea) {
            ;(parameters as StableDiffusionParameters).sampler = StableDiffusionSampler.kEulerAncestral
            ;(parameters as StableDiffusionParameters).sm = true
        }
        if ((parameters as StableDiffusionParameters).sampler === StableDiffusionSampler.naiSmeaDyn) {
            ;(parameters as StableDiffusionParameters).sampler = StableDiffusionSampler.kEulerAncestral
            ;(parameters as StableDiffusionParameters).sm = true
            ;(parameters as StableDiffusionParameters).sm_dyn = true
        }
        // If sm_dyn is set but sm is not, unset sm_dyn
        if (
            (parameters as StableDiffusionParameters).sm_dyn &&
            !(parameters as StableDiffusionParameters).sm
        ) {
            ;(parameters as StableDiffusionParameters).sm_dyn = false
        }

        // Disable sm and sm_dyn on unsupported samplers
        if (nonSmSamplers.has((parameters as StableDiffusionParameters).sampler)) {
            ;(parameters as StableDiffusionParameters).sm = false
            ;(parameters as StableDiffusionParameters).sm_dyn = false
        }

        // uc was renamed to negative_propmt
        if ((parameters as any).uc) {
            ;(parameters as any).negative_prompt = (parameters as any).uc
            delete (parameters as any).uc
        }

        // Don't send mask for non infill
        if (this.action !== 'infill') {
            delete (parameters as any).mask
        }

        // If uncond scale is 0, set to 0.00001
        if ((parameters as any).uncond_scale === 0) {
            ;(parameters as any).uncond_scale = 0.00001
        }

        // TODO: Get uncond scale working for other models
        // Prevent uncond scale for group one
        if (modelGroup(model) === ImageModelGroups.stableDiffusion) {
            delete (parameters as any).uncond_scale
        }

        // Only send noise_schedule for sdxl
        if (!getModelSupportInfo(model).noiseSchedule) {
            delete (parameters as any).cfg_rescale
            delete (parameters as any).noise_schedule
        }

        // Change reference_image params to _multiple versions
        // reference_image
        if ((parameters as any).reference_image) {
            ;(parameters as any).reference_image_multiple = (parameters as any).reference_image
            delete (parameters as any).reference_image
        }
        // reference_information_extracted
        if ((parameters as any).reference_information_extracted) {
            ;(parameters as any).reference_information_extracted_multiple = (
                parameters as any
            ).reference_information_extracted
            delete (parameters as any).reference_information_extracted
        }
        // reference_strength
        if ((parameters as any).reference_strength) {
            ;(parameters as any).reference_strength_multiple = (parameters as any).reference_strength
            delete (parameters as any).reference_strength
        }

        // Prevent DDIM for inpainting
        if (
            (model === ImageGenerationModels.naiDiffusionInpainting ||
                model === ImageGenerationModels.safeDiffusionInpainting ||
                model === ImageGenerationModels.naiDiffusionV3Inpainting ||
                model === ImageGenerationModels.furryDiffusionInpainting ||
                model === ImageGenerationModels.naiDiffusionFurryV3Inpainting) &&
            ((parameters as any).sampler === StableDiffusionSampler.ddim ||
                (parameters as any).sampler === StableDiffusionSampler.ddimv3)
        ) {
            ;(parameters as any).sampler = StableDiffusionSampler.kEulerAncestral
        }

        // Remove noise_schedule for nonNoiseScheduleSamplers
        if (nonNoiseScheduleSamplers.has((parameters as any).sampler)) {
            delete (parameters as any).noise_schedule
        }

        if (
            (parameters as any).sampler === StableDiffusionSampler.kEulerAncestral &&
            (parameters as any).noise_schedule !== StableDiffusionSamplerNoise.native
        ) {
            // these Euler Ancestral settings are better, but we limit the change to non-native schedules,
            // to preserve behaviour of existing native images. we have more freedom to change non-native
            // schedules, since their being very broken gave us an opportunity to overhaul them.

            // avoid adding stochastic noise on final step of sampling. admittedly not a very visible bug.
            ;(parameters as any).deliberate_euler_ancestral_bug = false
            // brownian tree noise gives stable convergence, making few-step samples better resemble many-step
            ;(parameters as any).prefer_brownian = true
        }

        // using truthiness comparison, because this behaviour is desired for non-zero, non-null and non-undefined
        if ((parameters as any).skip_cfg_above_sigma) {
            const { width: widthPx, height: heightPx } = parameters as any
            if (widthPx && heightPx) {
                const pixelResolution: Resolution = [widthPx, heightPx]
                const resampleFactor = getLatentResampleFactorForModel(model)
                const latentResolution: Resolution = pixelToLatentResolution(pixelResolution, resampleFactor)
                const channels: number = getChannelsForModel(model)
                const snrCoeff: number = getSNRMultiplierForResolution(channels, latentResolution)
                // a bigger image needs a higher std (sigma) of noise to destroy the same size of feature.
                // we scale the sigma cutoff w.r.t the amount of signal in our image vs our reference image.
                // this keeps the signal-to-noise ratio consistent (to the extent our assumptions are correct).
                // in reality, a 4x larger image area does not necessarily give us 4x more signal redundancy.
                // but this heuristic is likely to be better than not doing it.
                ;(parameters as any).skip_cfg_above_sigma *= snrCoeff
            }
        }

        //;(parameters as any).hide_debug_overlay = true

        this.parameters = parameters
    }
    id = randomShortID()
    async request(): Promise<Buffer[]> {
        const body: any = {
            input: this.input,
            model: this.model,
            action: this.action,
            parameters: this.parameters,
        }

        if ((this.parameters as StableDiffusionParameters).aiUrl) {
            body.url = (this.parameters as StableDiffusionParameters).aiUrl
            delete body.parameters.aiUrl
        }

        const request: RequestInit = {
            mode: 'cors',
            cache: 'no-store',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.user.auth_token,
                'x-correlation-id': this.id,
                'x-initiated-at': new Date().toISOString(),
            },
            method: 'POST',
            body: JSON.stringify(body),
        }

        // Fetch the image
        return fetchWithTimeout(BackendUrl.GenerateImage, request, 120000)
            .then((response) => {
                if (!response.ok) {
                    // Read body to get error message
                    return response.text().then((text) => {
                        // text is a JSON string with statusCode and message
                        const error = JSON.parse(text)
                        throw {
                            message: 'Error generating image: ' + error.statusCode + ' ' + error.message,
                            statusCode: error.statusCode,
                        }
                    })
                }
                // Response is a zip file containing one or more images named image_{n}.png
                return response.blob()
            })
            .then((blob) => JSZip.loadAsync(blob))
            .then(async (zip) => {
                const images: Buffer[] = []
                for (const filename of Object.keys(zip.files)) {
                    if (filename.startsWith('image_') && filename.endsWith('.png')) {
                        const file = zip.file(filename)
                        if (!file) {
                            throw new Error('Error generating image: file not found')
                        }
                        const buffer = await file.async('nodebuffer')
                        images.push(buffer)
                    }
                }
                return images
            })
            .catch((error) => {
                error.correlationId = this.id
                logError(error, false)
                throw error
            })
    }
}

export class RemoteImageAnnotateRequest {
    user: User
    model: ImageAnnotationModels
    parameters: any
    constructor(user: User, model: ImageAnnotationModels, parameters: any) {
        this.user = user
        this.model = model
        this.parameters = parameters
    }
    id = randomShortID()
    async request(): Promise<Buffer[]> {
        const request: RequestInit = {
            mode: 'cors',
            cache: 'no-store',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.user.auth_token,
                'x-correlation-id': this.id,
                'x-initiated-at': new Date().toISOString(),
            },
            method: 'POST',
            body: JSON.stringify({
                model: this.model,
                parameters: this.parameters,
            }),
        }

        // Fetch the image
        return fetchWithTimeout(BackendUrl.AnnotateImage, request, 120000)
            .then((response) => {
                if (!response.ok) {
                    // Read body to get error message
                    return response.text().then((text) => {
                        // text is a JSON string with statusCode and message
                        const error = JSON.parse(text)
                        throw new Error('Error generating image: ' + error.statusCode + ' ' + error.message)
                    })
                }
                // Response is a zip file containing one or more images named image_{n}.png
                return response.blob()
            })
            .then((blob) => JSZip.loadAsync(blob))
            .then(async (zip) => {
                const images: Buffer[] = []
                for (const filename of Object.keys(zip.files)) {
                    if (filename.startsWith('image_') && filename.endsWith('.png')) {
                        const file = zip.file(filename)
                        if (!file) {
                            throw new Error('Error generating image: file not found')
                        }
                        const buffer = await file.async('nodebuffer')
                        images.push(buffer)
                    }
                }
                return images
            })
            .catch((error) => {
                error.correlationId = this.id
                logError(error, false)
                throw error
            })
    }
}

export class RemoteImageUpscaleRequest {
    user: User
    image: string
    height: number
    width: number
    scale: number
    constructor(user: User, image: string, height: number, width: number, scale: number) {
        this.user = user
        this.image = image
        this.height = height
        this.width = width
        this.scale = scale
    }
    async request(): Promise<Buffer[]> {
        const id = randomShortID()
        const request: RequestInit = {
            mode: 'cors',
            cache: 'no-store',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.user.auth_token,
                'x-correlation-id': id,
                'x-initiated-at': new Date().toISOString(),
            },
            method: 'POST',
            body: JSON.stringify({
                image: this.image,
                height: this.height,
                width: this.width,
                scale: this.scale,
            }),
        }
        // Fetch the image
        return fetchWithTimeout(BackendUrl.UpscaleImage, request, 120000)
            .then((response) => {
                if (!response.ok) {
                    // Read body to get error message
                    return response.text().then((text) => {
                        // text is a JSON string with statusCode and message
                        const error = JSON.parse(text)
                        throw new Error('Error generating image: ' + error.statusCode + ' ' + error.message)
                    })
                }
                // Response is a zip file containing one or more images named image_{n}.png
                return response.blob()
            })
            .then((blob) => JSZip.loadAsync(blob))
            .then(async (zip) => {
                const images: Buffer[] = []
                for (const filename of Object.keys(zip.files)) {
                    if (filename.startsWith('image') && filename.endsWith('.png')) {
                        const file = zip.file(filename)
                        if (!file) {
                            throw new Error('Error generating image: file not found')
                        }
                        const buffer = await file.async('nodebuffer')
                        images.push(buffer)
                    }
                }
                return images
            })
            .catch((error) => {
                error.correlationId = id
                logError(error, false)
                throw error
            })
    }
}

export class RemoteChangeMailingListConsentRequest {
    auth_token: string
    marketingConsent: boolean
    uiLanguage: Language
    email?: string
    constructor(auth_token: string, marketingConsent: boolean, uiLanguage: Language, email?: string) {
        this.auth_token = auth_token
        this.marketingConsent = marketingConsent
        this.email = email
        this.uiLanguage = uiLanguage
    }

    async request(): Promise<void> {
        const request: RequestInit = {
            mode: 'cors',
            cache: 'no-store',
            headers: {
                'Content-Type': 'application/json',
                authorization: 'Bearer ' + this.auth_token,
            },
            method: 'PATCH',
        }

        const body: any = {
            marketingConsent: this.marketingConsent,
            uiLanguage: this.uiLanguage,
            email: this.email,
        }

        request.body = JSON.stringify(body)

        const result = await fetchWithTimeout(BackendUrl.ChangeMailingListConsent, request)

        if (!result.ok) {
            logError(result, false)
            throw await formatErrorResponse(result)
        }

        return
    }
}

export class RemoteImageToolRequest {
    user: User
    tool: ImageTool
    parameters: any
    constructor(user: User, tool: ImageTool, parameters: any) {
        this.user = user
        this.tool = tool
        this.parameters = parameters

        // If tool is emotion, combine prompt and extra
        if (this.tool === ImageTool.emotion) {
            this.parameters.prompt = this.parameters.prompt + ';;' + this.parameters.extra
            delete this.parameters.extra
        }
    }
    id = randomShortID()
    async request(): Promise<Buffer[]> {
        const request: RequestInit = {
            mode: 'cors',
            cache: 'no-store',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.user.auth_token,
                'x-correlation-id': this.id,
                'x-initiated-at': new Date().toISOString(),
            },
            method: 'POST',
            body: JSON.stringify({
                req_type: this.tool,
                ...this.parameters,
            }),
        }

        // Fetch the image
        return fetchWithTimeout(BackendUrl.ImageTool, request, 120000)
            .then((response) => {
                if (!response.ok) {
                    // Read body to get error message
                    return response.text().then((text) => {
                        // text is a JSON string with statusCode and message
                        const error = JSON.parse(text)
                        throw new Error(
                            `[${this.id}] Error generating image: ${error.statusCode} ${error.message}`
                        )
                    })
                }
                // Response is a zip file containing one or more images named image_{n}.png
                return response.blob()
            })
            .then((blob) => JSZip.loadAsync(blob))
            .then(async (zip) => {
                const images: Buffer[] = []
                for (const filename of Object.keys(zip.files)) {
                    if (filename.startsWith('image') && filename.endsWith('.png')) {
                        const file = zip.file(filename)
                        if (!file) {
                            throw new Error(`[${this.id}] Error generating image: file not found`)
                        }
                        const buffer = await file.async('nodebuffer')
                        images.push(buffer)
                    }
                }
                return images
            })
            .catch((error) => {
                error.correlationId = this.id
                logError(error, false)
                throw error
            })
    }
}
