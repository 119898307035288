import { EncoderType } from '../../tokenizer/enums'
import { WorkerInterface } from '../../tokenizer/interface'
import { KeyStore } from '../storage/keystore/keystore'
import { StorySettings, TextGenerationSettings } from '../story/storysettings'
import { User, UserInformation } from '../user/user'
import { UserSettings } from '../user/settings'
import {
    IGenerationRequest,
    IGenerationRequestResponse,
    ILoginRequest,
    ILoginResponse,
    IRegisterRequest,
    IRegisterResponse,
    IRecoveryInitiationRequest,
    ISubscriptionBindRequest,
    ISubscriptionChangeRequest,
    ISubscriptionRequest,
    ISubscriptionResponse,
    IRecoverySubmitRequest,
    StableDiffusionParameters,
    ImageGenerationModels,
    DalleMiniParameters,
    ISubscriptionBindResponse,
    ImageGenerationAction,
    ImageAnnotationModels,
} from './request'
import { DefaultModel, TextGenerationModel } from './model'
import { ImageTool } from '../image/imagetoolutil'
import { Language } from '../../hooks/useLocalization'

export class MockGenerationRequest implements IGenerationRequest {
    context: number[]
    parameters: TextGenerationSettings
    user: User
    model: TextGenerationModel = DefaultModel

    constructor(user: User, context: number[], settings: StorySettings) {
        this.context = context
        this.parameters = JSON.parse(JSON.stringify(settings.parameters))
        this.user = user
    }

    async request(): Promise<IGenerationRequestResponse> {
        await new Promise((r) => setTimeout(r, 500))
        const response = ' ' + (await new WorkerInterface().decode([...this.context], EncoderType.GPT2))
        return {
            text: response.slice(-50),
        }
    }

    async requestStream(): Promise<void> {
        throw 'unimplemented'
    }

    async wrappedRequest(): Promise<void> {
        throw 'unimplemented'
    }
}

export class MockLoginRequest implements ILoginRequest {
    access_key: string
    encryption_key: string
    auth_token?: string

    constructor(access_key: string, encryption_key: string, auth_token?: string) {
        this.access_key = access_key
        this.encryption_key = encryption_key
        this.auth_token = auth_token
    }

    async login(): Promise<ILoginResponse> {
        const response: ILoginResponse = {
            settings: new UserSettings(),
            session: {
                authenticated: true,
                keystore: new KeyStore(),
                auth_token: this.auth_token ?? 'mock',
            },
            subscription: {
                tier: 0,
                expiresAt: 0,
                active: false,
                perks: {
                    maxPriorityActions: 1000,
                    startPriority: 10,
                    contextTokens: 2048,
                    unlimitedMaxPriority: true,
                },
                paymentProcessorData: undefined,
                trainingStepsLeft: 100,
                accountType: 0,
            },
            priority: {
                maxPriorityActions: 1000,
                nextRefillAt: 0,
                taskPriority: 0,
            },
            information: new UserInformation(),
        }
        return response
    }
}

export class MockRegisterRequest implements IRegisterRequest {
    access_key: string
    captcha: string
    encryption_key: string
    email: string
    gift_key?: string
    locale?: string
    allowMarketingEmails?: boolean

    constructor(
        access_key: string,
        encryption_key: string,
        captcha: string,
        email: string,
        gift_key?: string,
        locale?: string,
        allowMarketingEmails?: boolean
    ) {
        this.access_key = access_key
        this.captcha = captcha
        this.encryption_key = encryption_key
        this.email = email
        this.gift_key = gift_key
        this.locale = locale
        this.allowMarketingEmails = allowMarketingEmails
    }

    async register(): Promise<IRegisterResponse> {
        return { auth_token: 'mock' }
    }
}

export class MockSubscriptionRequest implements ISubscriptionRequest {
    auth_token: string
    constructor(auth_token: string) {
        this.auth_token = auth_token
    }
    async request(): Promise<ISubscriptionResponse> {
        return {
            subscription: {
                tier: 0,
                expiresAt: 0,
                active: false,
                perks: {
                    maxPriorityActions: 1000,
                    startPriority: 10,
                    contextTokens: 2048,
                    unlimitedMaxPriority: true,
                },
                paymentProcessorData: undefined,
                trainingStepsLeft: 100,
                accountType: 0,
            },
            priority: {
                maxPriorityActions: 1000,
                nextRefillAt: 0,
                taskPriority: 0,
            },
        }
    }
}

export class MockSubscriptionBindRequest implements ISubscriptionBindRequest {
    auth_token: string
    paymentProcessor: string
    subscriptionId: string

    constructor(auth_token: string, paymentProcessor: string, subscriptionId: string) {
        this.auth_token = auth_token
        this.paymentProcessor = paymentProcessor
        this.subscriptionId = subscriptionId
    }

    async request(): Promise<ISubscriptionBindResponse> {
        throw 'unimplemented'
    }
}

export class MockSubscriptionChangeRequest implements ISubscriptionChangeRequest {
    auth_token: string
    newSubscriptionPlan: string

    constructor(auth_token: string, newSubscriptionPlan: string) {
        this.auth_token = auth_token
        this.newSubscriptionPlan = newSubscriptionPlan
    }

    async request(): Promise<void> {
        return
    }
}

export class MockRecoveryInitiationRequest implements IRecoveryInitiationRequest {
    email: string
    locale?: string

    constructor(email: string, locale?: string) {
        this.email = email
        this.locale = locale
    }

    async request(): Promise<void> {
        return
    }
}

export class MockMockRecoverySubmitRequest implements IRecoverySubmitRequest {
    recoveryToken: string
    newAccessKey: string

    constructor(recoveryToken: string, newAccessKey: string) {
        this.recoveryToken = recoveryToken
        this.newAccessKey = newAccessKey
    }

    async request(): Promise<void> {
        return
    }
}

export class MockPurchaseStepsRequest {
    async request(): Promise<void> {
        return
    }
}

export class MockVerifyEmailRequest {
    async request(): Promise<void> {
        return
    }
}

export class MockDeleteAccountRequest {
    async request(): Promise<void> {
        return
    }
}

export class MockImageGenerationRequest {
    user: User
    input: string
    model: ImageGenerationModels
    action: ImageGenerationAction
    parameters: StableDiffusionParameters | DalleMiniParameters
    constructor(
        user: User,
        input: string,
        model: ImageGenerationModels,
        action: ImageGenerationAction,
        parameters: StableDiffusionParameters | DalleMiniParameters
    ) {
        this.user = user
        this.input = input
        this.parameters = parameters
        this.model = model
        this.action = action
    }
    async request(): Promise<Buffer[]> {
        throw 'unimplemented'
    }
}

export class MockImageAnnotateRequest {
    user: User
    model: ImageAnnotationModels
    parameters: any
    constructor(user: User, model: ImageAnnotationModels, parameters: any) {
        this.user = user
        this.model = model
        this.parameters = parameters
    }
    async request(): Promise<Buffer[]> {
        throw 'unimplemented'
    }
}

export class MockImageUpscaleRequest {
    user: User
    image: string
    height: number
    width: number
    scale: number
    constructor(user: User, image: string, height: number, width: number, scale: number) {
        this.user = user
        this.image = image
        this.height = height
        this.width = width
        this.scale = scale
    }
    request(): Promise<Buffer[]> {
        throw 'unimplemented'
    }
}

export class MockChangeMailingListConsentRequest {
    auth_token: string
    marketingConsent: boolean
    uiLanguage: Language
    email?: string
    constructor(auth_token: string, marketingConsent: boolean, uiLanguage: Language, email?: string) {
        this.auth_token = auth_token
        this.marketingConsent = marketingConsent
        this.email = email
    }

    async request(): Promise<void> {
        return
    }
}

export class MockImageToolRequest {
    user: User
    tool: ImageTool
    parameters: any
    constructor(user: User, tool: ImageTool, parameters: any) {
        this.user = user
        this.tool = tool
        this.parameters = parameters
    }
    async request(): Promise<Buffer[]> {
        throw 'unimplemented'
    }
}
